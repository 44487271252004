import { type Database } from "@fitness-app/supabase";

import { MealType, type Dish, type MealTypeEnum } from "./Dish";
import { type IngredientWithPortion } from "./Ingredient";

export type MealsPlanEntity = Database["public"]["Tables"]["meals_plan"]["Row"];
export type MealsPlanDetailsEntity = Database["public"]["Tables"]["meals_plan_details"]["Row"];
export type MealsDayEmbeddingEntity = Database["public"]["Tables"]["meals_day_embedding"]["Row"];

export enum DietaryPreference {
  NoPreference = 1,
  SemiVegetarian = 2,
  Vegetarian = 3,
  Vegan = 4,
}

export enum DietaryExclusions {
  Lactose = "lactose",
  Casein = "casein",
  LowFoodmap = "lowFoodmap",
  Gluten = "gluten",
}

export enum TargetGender {
  MALE = "male",
  FEMALE = "female",
  UNISEX = "unisex",
}

export enum ActivityLevel {
  VERY_LOW = 1.25,
  LOW = 1.4,
  MEDIUM = 1.6,
  ACTIVE = 1.75,
  VERY_ACTIVE = 2,
  EXTREME = 2.3,
}

export enum MealsPerDay {
  TWO = 2,
  THREE = 3,
  FOUR = 4,
  FIVE = 5,
  SIX = 6,
}

export const WEEKDAYS = {
  MONDAY: "monday",
  TUESDAY: "tuesday",
  WEDNESDAY: "wednesday",
  THURSDAY: "thursday",
  FRIDAY: "friday",
  SATURDAY: "saturday",
  SUNDAY: "sunday",
} as const;

export const MEALS_SCHEMA: Record<MealsPerDay, Meal[]> = {
  [MealsPerDay.TWO]: [
    { name: null, type: MealType.BREAKFAST, id: MealType.BREAKFAST, hour: [7, 10], dishes: [], comment: "" },
    { name: null, id: MealType.DINNER, type: MealType.DINNER, hour: [13, 17], dishes: [], comment: "" },
  ],
  [MealsPerDay.THREE]: [
    { name: null, type: MealType.BREAKFAST, id: MealType.BREAKFAST, hour: [7, 10], dishes: [], comment: "" },
    { name: null, id: MealType.DINNER, type: MealType.DINNER, hour: [12.3, 16], dishes: [], comment: "" },
    { name: null, id: MealType.SUPPER, type: MealType.SUPPER, hour: [18, 21], dishes: [], comment: "" },
  ],
  [MealsPerDay.FOUR]: [
    { name: null, type: MealType.BREAKFAST, id: MealType.BREAKFAST, hour: [7, 10], dishes: [], comment: "" },
    { name: null, id: MealType.DINNER, type: MealType.DINNER, hour: [12.3, 16], dishes: [], comment: "" },
    { name: null, id: MealType.SNACK, type: MealType.SNACK, hour: [16, 19], dishes: [], comment: "" },
    { name: null, id: MealType.SUPPER, type: MealType.SUPPER, hour: [19, 21], dishes: [], comment: "" },
  ],
  [MealsPerDay.FIVE]: [
    { name: null, type: MealType.BREAKFAST, id: MealType.BREAKFAST, hour: [7, 9], dishes: [], comment: "" },
    {
      name: null,
      type: MealType.SECOND_BREAKFAST,
      id: MealType.SECOND_BREAKFAST,
      hour: [10.3, 11.3],
      dishes: [],
      comment: "",
    },
    { name: null, id: MealType.DINNER, type: MealType.DINNER, hour: [13, 16], dishes: [], comment: "" },
    { name: null, id: MealType.SNACK, type: MealType.SNACK, hour: [16, 19], dishes: [], comment: "" },
    { name: null, id: MealType.SUPPER, type: MealType.SUPPER, hour: [19, 22], dishes: [], comment: "" },
  ],
  [MealsPerDay.SIX]: [
    { name: null, type: MealType.BREAKFAST, id: MealType.BREAKFAST, hour: [7, 9], dishes: [], comment: "" },
    {
      name: null,
      type: MealType.SECOND_BREAKFAST,
      id: MealType.SECOND_BREAKFAST,
      hour: [10.3, 11.3],
      dishes: [],
      comment: "",
    },
    { name: null, type: MealType.LUNCH, id: MealType.LUNCH, hour: [12.3, 14], dishes: [], comment: "" },
    { name: null, id: MealType.DINNER, type: MealType.DINNER, hour: [14, 17], dishes: [], comment: "" },
    { name: null, id: MealType.SNACK, type: MealType.SNACK, hour: [17, 19], dishes: [], comment: "" },
    { name: null, id: MealType.SUPPER, type: MealType.SUPPER, hour: [19, 22], dishes: [], comment: "" },
  ],
};

export enum MealIngredientStatus {
  EMPTY = "empty",
  EATEN = "eaten",
  SKIPPED = "skipped",
}

export interface MealPlansTargets {
  protein: number;
  carbs: number;
  fat: number;
  macroUnit: "percentage" | "grams";
  gender: TargetGender | null;
  activityLevel: ActivityLevel | null;
}

export interface NutritionAttachment {
  timestamp?: string;
  uid: string;
  name: string;
  url: string;
  storageName: string;
  isGeneratedPdf?: boolean;
  status?: string;
}

export interface Supplement {
  name: string;
  type?: string;
  dose: string;
  comment: string;
  id: string;
  url: null | string;
}

export interface DishInMeal extends Dish {
  parentId: string | null;
  portion?: number;
  portionCount?: number;
  status?: MealIngredientStatus | null;
  clientNote?: string;
  description?: string;
  fromTracker?: boolean;
  source: string | null | "ai_analyzer";
}

export interface Meal {
  comment: string;
  id: string;
  type: MealTypeEnum;
  name: string | null;
  hour: number | [number, number] | null;
  dishes: (DishInMeal | IngredientWithPortion)[];
}

export interface NutritionPlanDay {
  createdAt: string;
  updatedAt: string;
  id: string;
  comment: string;
  isTrainingDay: boolean;
  meals: Meal[];
  name: string;
  trainingCalories: 0;
}

export const KCAL_PER_NUTRIENT_GRAM = {
  fat: 9,
  protein: 4,
  carbs: 4,
  fiber: 2,
} as const;

export enum MacroSplit {
  BALANCED = "balanced",
  LOW_CARB = "lowCarb",
  LOW_FAT = "lowFat",
  HIGH_PROTEIN = "highProtein",
  HIGH_CARB = "highCarb",
  KETOGENIC = "ketogenic",
}

export const MacroSplitRanges = {
  [MacroSplit.BALANCED]: {
    protein: 30,
    carbs: 40,
    fat: 30,
  },
  [MacroSplit.LOW_CARB]: {
    protein: 30,
    carbs: 20,
    fat: 50,
  },
  [MacroSplit.LOW_FAT]: {
    protein: 50,
    carbs: 30,
    fat: 20,
  },

  [MacroSplit.HIGH_CARB]: {
    protein: 15,
    carbs: 65,
    fat: 20,
  },
  [MacroSplit.HIGH_PROTEIN]: {
    protein: 40,
    carbs: 30,
    fat: 30,
  },
  [MacroSplit.KETOGENIC]: {
    protein: 20,
    carbs: 10,
    fat: 70,
  },
};

export interface MealsPlanDetails extends Omit<MealsPlanDetailsEntity, "supplements" | "days"> {
  id: string;
  createdAt: string;
  updatedAt: string;
  planId: string;
  supplements: null | Supplement[];
  weeks: { weekNumber: number; days: NutritionPlanDay[] }[];
}

export interface MealsPlan
  extends Omit<
    MealsPlanEntity,
    "attachments" | "targets" | "dietaryPreference" | "macroSplit" | "mealsSchema" | "metadata"
  > {
  id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
  dietaryPreference: DietaryPreference;
  macroSplit: MacroSplit;
  comment: string;
  targetCalories: number;
  targets: MealPlansTargets;
  attachments: NutritionAttachment[];
  mealsSchema: MealsPerDay;
  tags: string[] | null;
  metadata: null | Record<string, string | null | string[]>;
}

export interface MealsPlanWithCreator extends MealsPlan {
  creator: {
    firstName: string | null;
    lastName: string | null;
    id: string;
  } | null;
}

export interface MealsDayEmbedding extends Omit<MealsDayEmbeddingEntity, "macros" | "metadata" | "day"> {
  macros: {
    protein: number;
    carbs: number;
    fat: number;
    calories: number;
  };
  metadata: Record<string, string | number | null>;
  day: NutritionPlanDay;
}
