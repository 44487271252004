import { useEffect, useState, type FunctionComponent } from "react";
import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Popconfirm,
  Select,
  Space,
  Switch,
  Typography,
} from "antd";
import dayjs, { type Dayjs } from "dayjs";
import capitalize from "lodash.capitalize";
import { useTranslation } from "react-i18next";

import { productActions } from "@fitness-app/app-store";
import { RadixColor } from "@fitness-app/data-models/entities/CheckoutVariant";
import { ProductPriceAction, type ProductPrice } from "@fitness-app/data-models/entities/Product";
import { generateUniqId } from "@fitness-app/utils/src/helpers/generateUniqId";

import { useProductPricesOptions } from "~/modules/Products/hooks/useProductPricesOptions";
import { useAppDispatch } from "~/store/initializeStore";
import { colorThemeOptions } from "../ProductSettings/utils/theme-options";

interface OwnProps {
  price: ProductPrice;
  extended: boolean;
  productId: string;
  productPricesActions: null | { id: string; action: ProductPriceAction }[];
  canRemove: boolean;
}

type Props = OwnProps;

interface ConfigFormModel {
  showAsPromo: boolean;
  priceBeforeDiscount?: number;
  promoTagText?: string | null;
  promoTagColor?: RadixColor | null;
  showTimeLimit: boolean;
  priceTimeLimit?: Dayjs | null;
  showQuantityLimit: boolean;
  priceQuantityLimit?: number | null;
  showQuantityAvailable: boolean;
  showAdditionalInfo: boolean;
  priceAdditionalInfo?: string | null;
  setUpsell: boolean;
  upsellPrices?: string[] | null;
  onlyForRenewal?: boolean;
  forAccessProlongationNotRequirePayment?: boolean;
  allowManualPayment?: boolean;
}

const PriceConfig: FunctionComponent<Props> = ({ price, extended, productPricesActions, productId, canRemove }) => {
  const [showPromoModal, togglePromoModal] = useState(false);
  const [formController] = Form.useForm<ConfigFormModel>();
  const showAsPromo = Form.useWatch("showAsPromo", formController);
  const showTimeLimit = Form.useWatch("showTimeLimit", formController);
  const showQuantityLimit = Form.useWatch("showQuantityLimit", formController);
  const showQuantityAvailable = Form.useWatch("showQuantityAvailable", formController);
  const showAdditionalInfo = Form.useWatch("showAdditionalInfo", formController);
  const setUpsell = Form.useWatch("setUpsell", formController);
  const productPricesOptions = useProductPricesOptions(price.id);

  const [saving, toggleSavingFlag] = useState(false);
  const { t } = useTranslation("products");
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (showPromoModal) {
      const showAsPromo = Boolean(price.config?.showAsPromo);
      const showTimeLimit = Boolean(price.config?.activeUntil);
      const showQuantityLimit = Boolean(price.config?.quantityLimit);
      const showQuantityAvailable = Boolean(price.config?.showAvailable);
      const showAdditionalInfo = Boolean(price.config?.additionalInfo);
      formController?.setFieldsValue({
        showAsPromo,
        priceBeforeDiscount: price.config?.showAsPromo ? price.config.showAsPromo.priceBeforeDiscount / 100 : 0,
        showTimeLimit,
        priceTimeLimit: price.config?.activeUntil ? dayjs(price.config?.activeUntil) : null,
        showQuantityLimit,
        priceQuantityLimit: price.config?.quantityLimit ?? 100,
        showQuantityAvailable,
        showAdditionalInfo,
        priceAdditionalInfo: price.config?.additionalInfo,
        onlyForRenewal: price.config?.onlyForRenewal ?? false,
        forAccessProlongationNotRequirePayment: price.config?.forAccessProlongationNotRequirePayment ?? false,
        allowManualPayment: price.config?.allowManualPayment ?? false,
        promoTagText: price.config?.showAsPromo ? price.config.showAsPromo.promoTagText ?? null : null,
        promoTagColor: price.config?.showAsPromo ? price.config.showAsPromo.promoTagColor ?? null : null,
      });
    } else {
      setTimeout(() => {
        formController.resetFields();
      }, 300);
    }
  }, [showPromoModal, price.config]);

  const onConfigFormSubmit = async (data: ConfigFormModel) => {
    toggleSavingFlag(true);

    try {
      await dispatch(
        productActions.updateProductPriceConfig({
          priceId: price.id,
          productId,
          config: {
            additionalInfo: data.showAdditionalInfo ? data.priceAdditionalInfo : null,
            activeUntil: data.showTimeLimit ? data.priceTimeLimit?.valueOf() ?? null : null,
            quantityLimit: data.showQuantityLimit ? data.priceQuantityLimit : null,
            showAvailable: data.showQuantityAvailable ?? false,
            showAsPromo:
              data.showAsPromo && data.priceBeforeDiscount
                ? {
                    priceBeforeDiscount: data.priceBeforeDiscount * 100,
                    promoTagText: data.promoTagText ?? null,
                    promoTagColor: data.promoTagColor ?? null,
                  }
                : false,
            onlyForRenewal: data.onlyForRenewal ?? false,
            forAccessProlongationNotRequirePayment: data.forAccessProlongationNotRequirePayment ?? false,
            allowManualPayment: data.allowManualPayment ?? false,
          },
        }),
      ).unwrap();
      toggleSavingFlag(false);
      togglePromoModal(false);
    } catch {
      toggleSavingFlag(false);
      void message.error("common:messages.error.default");
    }

    await dispatch(
      productActions.updateProductPrices({
        payload: {
          prices: [
            {
              productId,
              priceId: price.id,
              connectedUpsells: data.upsellPrices ?? null,
              action: ProductPriceAction.SetUpsell,
            },
          ],
        },
        onSuccess: () => {
          toggleSavingFlag(false);
          togglePromoModal(false);
        },
        onFailure: () => {
          toggleSavingFlag(false);
        },
      }),
    );

    if (data.upsellPrices?.length) {
      data.upsellPrices.forEach(
        async (upsellPrice) =>
          await dispatch(
            productActions.updateProductPrices({
              payload: {
                prices: [
                  {
                    productId,
                    priceId: upsellPrice,
                    hidden: true,
                    checkoutId: generateUniqId(),
                    action: ProductPriceAction.ToggleVisibility,
                  },
                ],
              },
              onSuccess: () => {
                toggleSavingFlag(false);
                togglePromoModal(false);
              },
              onFailure: () => {
                toggleSavingFlag(false);
              },
            }),
          ),
      );
    }
  };

  const currentlyProcessed = productPricesActions ? productPricesActions.find((item) => item.id === price.id) : null;

  return (
    <>
      <Space>
        {extended && (
          <Button type="link" onClick={() => togglePromoModal(true)}>
            {t("common:button.configure")}
          </Button>
        )}
        {price.clients ? (
          <Popconfirm
            overlayStyle={{ maxWidth: 400 }}
            placement="leftTop"
            disabled={!canRemove}
            title={t("mailing.archivePriceWarning")}
            onConfirm={() =>
              dispatch(
                productActions.updateProductPrices({
                  onFailure: () => {
                    void message.error(t("errorWhenUpdatingPrice"));
                  },
                  payload: {
                    prices: [
                      {
                        productId,
                        priceId: price.id,
                        action: ProductPriceAction.Archive,
                      },
                    ],
                  },
                }),
              )
            }
          >
            <Button
              type="link"
              danger
              disabled={!canRemove}
              loading={currentlyProcessed ? currentlyProcessed.action === ProductPriceAction.Archive : false}
            >
              {t("common:button.archive")}
            </Button>
          </Popconfirm>
        ) : (
          <Popconfirm
            overlayStyle={{ maxWidth: 400 }}
            placement="leftTop"
            disabled={!canRemove}
            title={t("mailing.deletePriceWarning")}
            onConfirm={() =>
              dispatch(
                productActions.updateProductPrices({
                  onFailure: () => {
                    void message.error(t("errorWhenUpdatingPrice"));
                  },
                  payload: {
                    prices: [
                      {
                        productId,
                        priceId: price.id,
                        action: ProductPriceAction.Remove,
                      },
                    ],
                  },
                }),
              )
            }
          >
            <Button
              type="link"
              danger
              disabled={!canRemove}
              loading={currentlyProcessed ? currentlyProcessed.action === ProductPriceAction.Remove : false}
            >
              {t("common:button.delete")}
            </Button>
          </Popconfirm>
        )}
      </Space>
      <Modal
        visible={showPromoModal}
        width={600}
        onCancel={() => {
          togglePromoModal(false);
        }}
        okText={t("common:button.save")}
        title={t("prices.modalTitle")}
        onOk={() => formController?.submit()}
        okButtonProps={{
          loading: saving,
          disabled: saving,
        }}
      >
        <Form<ConfigFormModel>
          wrapperCol={{ span: 16 }}
          onFinish={onConfigFormSubmit}
          form={formController}
          initialValues={{
            showAsPromo: false,
            priceBeforeDiscount: 0,
            showTimeLimit: false,
            priceTimeLimit: null,
            showQuantityLimit: false,
            priceQuantityLimit: 100,
            showQuantityAvaliable: false,
            promoTagColor: RadixColor.Red,
          }}
        >
          <Form.Item label={t("prices.showAsPromo")} name="showAsPromo" valuePropName="checked">
            <Switch />
          </Form.Item>
          {showAsPromo && (
            <>
              <Form.Item
                label={t("prices.priceBefore")}
                name="priceBeforeDiscount"
                tooltip={t("prices.willBeShowsStrikethrough")}
                rules={[
                  {
                    required: showAsPromo,
                    type: "number",
                    message: t<string>("common:validationErrors.fieldIsRequired"),
                  },
                  {
                    validator: (_, value) =>
                      value > (price.unit_amount ?? 0) / 100
                        ? Promise.resolve()
                        : Promise.reject(new Error(t<string>("prices.cannotBeLessThanCurrent"))),
                  },
                ]}
              >
                <InputNumber
                  min={0}
                  max={100000}
                  precision={2}
                  addonAfter={price.currency.toUpperCase()}
                  decimalSeparator={price.currency === "pln" ? "," : "."}
                />
              </Form.Item>

              <Form.Item name={["promoTagColor"]} label={t<string>("pageConfig.promoTagColor")}>
                <Select style={{ width: 180 }}>
                  {colorThemeOptions().map((item) => (
                    <Select.Option key={item.value} value={item.value}>
                      {t<string>(`${capitalize(item.value)}`)}
                      {item.color && (
                        <div
                          style={{
                            position: "absolute",
                            right: "1.2rem",
                            top: ".35rem",
                            width: "1.2rem",
                            height: "1.2rem",
                            backgroundColor: item.color,
                            borderRadius: ".6rem",
                          }}
                        />
                      )}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item label={t("pageConfig.promoTagText")} name="promoTagText">
                <Input
                  count={{
                    max: 30,
                    show: true,
                  }}
                />
              </Form.Item>
            </>
          )}

          <Form.Item label={t("prices.showTimeLimit")} name="showTimeLimit" valuePropName="checked">
            <Switch />
          </Form.Item>
          {showTimeLimit && (
            <Form.Item
              label={t("prices.priceTime")}
              name="priceTimeLimit"
              tooltip={t("prices.willBeShowsTimer")}
              rules={[
                {
                  required: showTimeLimit,
                  message: t<string>("common:validationErrors.fieldIsRequired"),
                },
                {
                  validator: (_, value) =>
                    value > Date.now()
                      ? Promise.resolve()
                      : Promise.reject(new Error(t<string>("prices.cannotBeEarlierThanNow"))),
                },
              ]}
            >
              <DatePicker
                showTime
                format="YYYY-MM-DD | HH:mm"
                disabledDate={(current) => current && current.valueOf() < Date.now() - 60 * 60 * 24}
              />
            </Form.Item>
          )}

          <Form.Item label={t("prices.showQuantityLimit")} name="showQuantityLimit" valuePropName="checked">
            <Switch />
          </Form.Item>
          {showQuantityLimit && (
            <>
              <Form.Item
                label={t("prices.priceQuantity")}
                name="priceQuantityLimit"
                tooltip={t("prices.willBeShowsQuantity")}
                rules={[
                  {
                    required: showQuantityLimit,
                    type: "number",
                    message: t<string>("common:validationErrors.fieldIsRequired"),
                  },
                  {
                    validator: (_, value) =>
                      value >= price.clients
                        ? Promise.resolve()
                        : Promise.reject(new Error(t<string>("prices.cannotBeLessThanSold"))),
                  },
                ]}
              >
                <InputNumber min={0} max={100000} />
              </Form.Item>
              <Form.Item label={t("prices.showQuantityAvailable")} name="showQuantityAvailable" valuePropName="checked">
                <Switch />
              </Form.Item>
              {showQuantityAvailable && (
                <div className="-mt-4 mb-4">
                  <Typography.Text type="secondary">{t("prices.quantityAvailableExample")}</Typography.Text>
                </div>
              )}
            </>
          )}
          <Form.Item label={t("prices.showAdditionalInfo")} name="showAdditionalInfo" valuePropName="checked">
            <Switch />
          </Form.Item>
          {showAdditionalInfo && (
            <>
              <Form.Item
                label={t("prices.additionalInfo")}
                name="priceAdditionalInfo"
                tooltip={t("prices.willBeShowsInfo")}
                rules={[
                  {
                    required: showAdditionalInfo,
                    message: t<string>("common:validationErrors.fieldIsRequired"),
                  },
                ]}
              >
                <Input style={{ maxWidth: 280 }} />
              </Form.Item>
              <div className="-mt-4 mb-4">
                <Typography.Text type="secondary">{t("prices.additionalInfoExample")}</Typography.Text>
              </div>
            </>
          )}
          <Form.Item label={t<string>("prices.setUpsell")} name="setUpsell" valuePropName="checked">
            <Switch />
          </Form.Item>
          {setUpsell && (
            <Form.Item
              name="upsellPrices"
              label={t<string>("prices.upsellPrices")}
              tooltip={t<string>("prices.upsellInfo")}
              rules={[
                {
                  required: setUpsell,
                  message: t<string>("common:validationErrors.fieldIsRequired"),
                },
              ]}
            >
              <Select mode="multiple" options={productPricesOptions} />
            </Form.Item>
          )}

          <Form.Item
            tooltip={t<string>("prices.onlyForRenewalTooltip")}
            label={t<string>("prices.onlyForRenewal")}
            name="onlyForRenewal"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>

          {price.installments && (
            <Form.Item
              tooltip={t<string>("prices.forAccessProlongationNotRequirePaymentTooltip")}
              label={t<string>("prices.forAccessProlongationNotRequirePayment")}
              name="forAccessProlongationNotRequirePayment"
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          )}

          {
            <Form.Item
              label={t("prices.allowManualPayment")}
              tooltip={t("prices.allowManualPaymentTooltip")}
              name="allowManualPayment"
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          }
        </Form>
      </Modal>
    </>
  );
};

export default PriceConfig;
