import { type TFunction } from "i18next";

import { ProductEventType } from "@fitness-app/data-models/businessEvents/domain/product/ProductEvents";
import { TraineeEventType } from "@fitness-app/data-models/businessEvents/domain/trainee/TraineeEvents";
import {
  ProductChargeType,
  ProductType,
  type ProductChargeTypeEnum,
  type ProductTypeEnum,
} from "@fitness-app/data-models/entities/Product";

export const createTriggerOptions = (
  t: TFunction<string[]>,
  productType?: ProductTypeEnum,
  productChargeType?: ProductChargeTypeEnum | null,
) => {
  const options: { label: string; value: ProductEventType | TraineeEventType.TraineeActivatedAccount }[] = [
    {
      label: t(`common:events.product.${ProductEventType.ProductPurchased.split(".").pop()}`),
      value: ProductEventType.ProductPurchased,
    },
    {
      label: t(`common:events.product.${ProductEventType.ProductClientImported.split(".").pop()}`),
      value: ProductEventType.ProductClientImported,
    },
  ];
  if (productChargeType === ProductChargeType.PAID) {
    options.push(
      ...[
        {
          label: t(`common:events.product.${ProductEventType.ProductSubscriptionEnd.split(".").pop()}`),
          value: ProductEventType.ProductSubscriptionEnd,
        },
        {
          label: t(`common:events.product.${ProductEventType.ProductSubscriptionRenewed.split(".").pop()}`),
          value: ProductEventType.ProductSubscriptionRenewed,
        },
        {
          label: t(`common:events.product.${ProductEventType.ProductAccessRenewed.split(".").pop()}`),
          value: ProductEventType.ProductAccessRenewed,
        },
        {
          label: t(`common:events.product.${ProductEventType.ProductAccessPeriodEnd.split(".").pop()}`),
          value: ProductEventType.ProductAccessPeriodEnd,
        },
        {
          label: t(`common:events.product.${ProductEventType.ProductAccessPeriodEndingIn14Days.split(".").pop()}`),
          value: ProductEventType.ProductAccessPeriodEndingIn14Days,
        },
        {
          label: t(`common:events.product.${ProductEventType.ProductAccessPeriodEndingIn7Days.split(".").pop()}`),
          value: ProductEventType.ProductAccessPeriodEndingIn7Days,
        },
        {
          label: t(`common:events.product.${ProductEventType.ProductAccessPeriodEndingIn3Days.split(".").pop()}`),
          value: ProductEventType.ProductAccessPeriodEndingIn3Days,
        },
        {
          label: t(`common:events.product.${ProductEventType.ProductAccessPeriodEndingIn1Day.split(".").pop()}`),
          value: ProductEventType.ProductAccessPeriodEndingIn1Day,
        },
        {
          label: t(`common:events.product.${ProductEventType.ProductClientArchived.split(".").pop()}`),
          value: ProductEventType.ProductClientArchived,
        },
        {
          label: t(`common:events.product.${ProductEventType.ProductAccessProlonged.split(".").pop()}`),
          value: ProductEventType.ProductAccessProlonged,
        },
        {
          label: t(`common:events.product.${ProductEventType.ProductAccessRescheduled.split(".").pop()}`),
          value: ProductEventType.ProductAccessRescheduled,
        },
      ],
    );
  } else {
    options.push({
      label: t(`common:events.product.${ProductEventType.ProductClientRemoved.split(".").pop()}`),
      value: ProductEventType.ProductClientRemoved,
    });
  }

  if (productType === ProductType.AUTOMATED_CLIENT_SERVICE || productType === ProductType.PERSONAL_CLIENT_SERVICE) {
    options.push({
      label: t(`common:${TraineeEventType.TraineeActivatedAccount}`),
      value: TraineeEventType.TraineeActivatedAccount,
    });
  }

  return options;
};
