import groupBy from "lodash.groupby";
import uniqBy from "lodash.uniqby";
import { createSelector } from "reselect";

import { type ClientDishOccurrenceWithPlan } from "@fitness-app/data-models/entities/ClientDishOccurrence";
import { ClientNutritionStatus } from "@fitness-app/data-models/entities/ClientNutrition";
import { generateUniqId } from "@fitness-app/utils/src/helpers/generateUniqId";

import { type AppStore } from "../../../index";

export const getCurrentMealsPlan = (state: AppStore) => state.traineeMealsPlan.currentMealsPlanDetails;
export const getDishOccurrence = (state: AppStore) => state.traineeMealsPlan.dishesOccurrence;

export const getSelectedPlan = (state: AppStore) => state.traineeMealsPlan.selectedMealsPlan;

export const getDishOccurrenceForSelectedPlan = createSelector(
  [getCurrentMealsPlan, getDishOccurrence, getSelectedPlan],
  (currentMealsPlan, dishesOccurrence, selectedPlan): Record<string, ClientDishOccurrenceWithPlan[]> => {
    if (!selectedPlan || dishesOccurrence === null) {
      return {};
    }

    if (selectedPlan.status !== ClientNutritionStatus.Scheduled || !currentMealsPlan) {
      return groupBy(dishesOccurrence, "name");
    }

    const dishes = currentMealsPlan.weeks[0]?.days.map((day) => day.meals.map((meal) => meal.dishes).flat()).flat();

    const uniqueDishes = uniqBy(dishes, "name");

    const dishesOccurrenceForCurrentPlan = uniqueDishes.map((dish) => ({
      id: generateUniqId(),
      dishId: dish.type !== "ingredient" ? dish.parentId : null,
      ingredientId: dish.type === "ingredient" ? dish.ingredientId : null,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      name: dish.name,
      planId: currentMealsPlan.planId,
      traineeId: "",
      plan: {
        name: currentMealsPlan.name,
        startAt: currentMealsPlan.startAt,
        endAt: currentMealsPlan.endAt,
      },
      metadata: null,
    }));

    const merged = [...(dishesOccurrence || []), ...dishesOccurrenceForCurrentPlan];

    return groupBy(merged, "name");
  },
);
