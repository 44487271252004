import { type Database } from "@fitness-app/supabase";

export type CheckoutVariantEntity = Database["public"]["Tables"]["checkout_variant"]["Row"];

export type VariantPrice = string;

export enum CheckoutVariantStatus {
  Active = "active",
  Inactive = "inactive",
  Archived = "archived",
}

export enum CheckoutVariantTarget {
  All = "all",
  New = "new",
  Renewal = "renewal",
}

interface PricingFeature {
  name: string;
  value?: string;
  originalPrice?: string;
  isFree?: boolean;
  hidden?: boolean;
}

interface PricingPlan {
  title: string;
  subtitle?: string;
  regularPrice: string;
  promoPrice: string;
  monthlyInfo: string;
  oneTimeInfo?: string;
  features: PricingFeature[];
  bonuses: PricingFeature[];
}

export interface CustomVariantConsent {
  label: string;
  url: string | null;
  required: boolean;
  name: string;
  applyToProducts: true;
  filteredByPriceId?: null | string[];
}

export enum RadixColor {
  Tomato = "tomato",
  Red = "red",
  Ruby = "ruby",
  Crimson = "crimson",
  Pink = "pink",
  Plum = "plum",
  Purple = "purple",
  Violet = "violet",
  Iris = "iris",
  Indigo = "indigo",
  Blue = "blue",
  Cyan = "cyan",
  Teal = "teal",
  Jade = "jade",
  Green = "green",
  Grass = "grass",
  Brown = "brown",
  Orange = "orange",
  Sky = "sky",
  Mint = "mint",
  Lime = "lime",
  Yellow = "yellow",
  Amber = "amber",
  Gold = "gold",
  Bronze = "bronze",
  Gray = "gray",
}

export const RADIX_COLOR_MAP: Record<RadixColor, string> = {
  [RadixColor.Tomato]: "#e54d2e",
  [RadixColor.Red]: "#e5484d",
  [RadixColor.Ruby]: "#e54666",
  [RadixColor.Crimson]: "#e93d82",
  [RadixColor.Pink]: "#d6409f",
  [RadixColor.Orange]: "#f76b15",
  [RadixColor.Lime]: "#bdee63",
  [RadixColor.Yellow]: "#ffe629",
  [RadixColor.Amber]: "#ffc53d",
  [RadixColor.Plum]: "#ab4aba",
  [RadixColor.Purple]: "#8e4ec6",
  [RadixColor.Violet]: "#6e56cf",
  [RadixColor.Iris]: "#5b5bd6",
  [RadixColor.Indigo]: "#3e63dd",
  [RadixColor.Blue]: "#0090ff",
  [RadixColor.Cyan]: "#00a2c7",
  [RadixColor.Sky]: "#7ce2fe",
  [RadixColor.Mint]: "#86ead4",
  [RadixColor.Teal]: "#12a594",
  [RadixColor.Jade]: "#29a383",
  [RadixColor.Green]: "#30a46c",
  [RadixColor.Grass]: "#46a758",
  [RadixColor.Brown]: "#ad7f58",
  [RadixColor.Bronze]: "#a18072",
  [RadixColor.Gold]: "#978365",
  [RadixColor.Gray]: "#8d8d8d",
};

export interface PageConfiguration {
  pricing?: PricingPlan[];
  productNameOverride?: string;
  productImageOverride?: string;
  productDescriptionOverride?: string;
  customConsents?: CustomVariantConsent[];
  pricingDisabled?: boolean;
  timer?: false | { title: string; description: string; startInfo: string; titleColor?: RadixColor };
  promoVideo?: {
    title: string;
    videoId: string;
    description?: string;
    hideIcons?: boolean;
  };
  enableLandingPage?: boolean;
  videoOnTop?: boolean;
  renewalFallback?: string;
}

export interface CheckoutVariant
  extends Omit<CheckoutVariantEntity, "metadata" | "prices" | "pricesStats" | "pageConfiguration"> {
  prices: VariantPrice[];
  pricesStats: Record<string, number>;
  metadata: Record<string, string | number | null | boolean | string[] | number[]> | null;
  status: CheckoutVariantStatus;
  target: CheckoutVariantTarget;
  pageConfiguration: PageConfiguration | null;
}
