import { memo, useMemo } from "react";
import { ArrowLeftOutlined, ArrowRightOutlined, DeleteOutlined } from "@ant-design/icons";
import { Avatar, Tooltip } from "antd";
import dayjs from "dayjs";
import groupBy from "lodash.groupby";

import { updateChatMessage } from "@fitness-app/app-store/src/store/reducers/chat/actions";
import { UserRole } from "@fitness-app/data-models";
import { type ChatMember, type ChatMessage, type MessageReaction } from "@fitness-app/data-models/entities/Chat";
import { getChatMemberName } from "@fitness-app/utils/src/chat/nameUtils";
import { cn } from "@fitness-app/utils/src/styles/cn";

import { useHorizontalSlider } from "~/components/Upload/hooks/useHorizontalSlider";
import { useUserRole } from "~/hooks/trainer/useUserRole";
import { useAppDispatch } from "~/store/initializeStore";

interface ChatMessageReactionsProps {
  messageId: string;
  channelId: string;
  reactions: ChatMessage["reactions"];
  incomingMessage: boolean;
  position?: "left" | "right";
  authorsById: Record<string, ChatMember>;
  authorId: string;
}

const ITEM_OFFSET = 8;

const ChatMessageReactions = ({
  reactions,
  position,
  authorsById,
  messageId,
  authorId,
  channelId,
}: ChatMessageReactionsProps) => {
  const groupByCode = useMemo(() => (reactions ? groupBy(reactions, "icon") : []), [reactions]);
  const { userId } = useUserRole();
  const dispatch = useAppDispatch();

  const { contentRef, containerRef, handleScroll, isArrowRightVisible, isArrowLeftVisible, moveToNextItem } =
    useHorizontalSlider(Object.keys(groupByCode), {
      additionalLeftOffset: 35,
      additionalRightOffset: 35,
      itemOffset: ITEM_OFFSET,
      scrollToRightOnItemsChange: true,
    });

  if (!reactions?.length) {
    return null;
  }

  const handleReactionClick = (selectedReaction?: MessageReaction) => {
    if (!selectedReaction) {
      return;
    }
    if (reactions.find((reaction) => reaction.icon === selectedReaction.icon && reaction.authorId === userId)) {
      void dispatch(
        updateChatMessage({
          channelId,
          authorId,
          id: messageId,
          reactions: reactions.filter((r) => !(r.icon === selectedReaction.icon && r.authorId === userId)),
        }),
      );
    } else {
      void dispatch(
        updateChatMessage({
          authorId,
          id: messageId,
          channelId,
          reactions: [
            ...(reactions ?? []),
            {
              iconCode: selectedReaction.iconCode,
              addedAt: dayjs().unix(),
              authorId: userId,
              icon: selectedReaction.icon,
              iconName: selectedReaction.iconName,
            },
          ],
        }),
      );
    }
  };

  return (
    <div
      ref={containerRef}
      className={cn(
        "relative z-10 -mt-2.5 max-w-full overflow-x-hidden pb-2 pt-4",
        position === "right" && "flex justify-end",
      )}
    >
      {isArrowLeftVisible && (
        <div
          role="button"
          onClick={moveToNextItem("left")}
          className="absolute left-1 top-[42%] z-10 flex h-7 w-7 cursor-pointer items-center justify-center rounded-full border border-gray-400 bg-white shadow-md outline-none transition-all duration-200 ease-in-out hover:scale-110"
        >
          <ArrowLeftOutlined style={{ fontSize: 14 }} />
        </div>
      )}
      <ul
        ref={contentRef}
        onScroll={handleScroll}
        className="scrollbar flex snap-x list-none gap-x-2 overflow-x-scroll scroll-smooth px-3 pt-2.5"
      >
        {Object.entries(groupByCode).map(([code, reactions]) => (
          <Tooltip
            key={code}
            title={
              <div className="flex flex-col gap-y-1.5">
                {reactions
                  .map((r) => r.authorId)
                  .map((authorId) => (
                    <span key={authorId} className="flex items-center gap-x-2">
                      <Avatar
                        size={26}
                        src={authorsById[authorId]?.avatarUrl}
                        gap={6}
                        style={
                          authorsById[authorId]?.avatarUrl
                            ? {}
                            : {
                                backgroundColor:
                                  authorsById[authorId]?.role === UserRole.CLIENT
                                    ? "rgb(247, 194, 93)"
                                    : "rgb(24, 26, 101)",
                              }
                        }
                        icon={!authorsById[authorId] ? <DeleteOutlined /> : undefined}
                      >
                        {authorsById[authorId] ? getChatMemberName(authorsById[authorId]) : "Deleted"}
                      </Avatar>
                      {getChatMemberName(authorsById[authorId])}
                    </span>
                  ))}
              </div>
            }
            destroyTooltipOnHide
          >
            <span
              className="flex h-8 cursor-pointer items-center justify-center rounded-full bg-sky-400 p-2 text-lg hover:bg-sky-600"
              role="button"
              onClick={() => handleReactionClick(reactions[0])}
            >
              {reactions[0]?.icon} <span className="ml-1 text-xs font-bold text-white">{reactions.length}</span>
            </span>
          </Tooltip>
        ))}
      </ul>

      {isArrowRightVisible && (
        <div
          role="button"
          onClick={moveToNextItem("right")}
          className="absolute right-1 top-[42%] z-10 flex h-7 w-7 cursor-pointer items-center justify-center rounded-full border border-gray-400 bg-white shadow-md outline-none transition-all duration-200 ease-in-out hover:scale-110"
        >
          <ArrowRightOutlined style={{ fontSize: 14 }} />
        </div>
      )}
    </div>
  );
};

export default memo(ChatMessageReactions);
