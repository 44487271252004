import { useEffect, useState, type FunctionComponent } from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Button, Card, DatePicker, Divider, Input, Space, Table, Tag, Tooltip } from "antd";
import dayjs, { type Dayjs } from "dayjs";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { productOrdersActions, productOrdersSelectors, RequestStatus } from "@fitness-app/app-store";
import { OrderStatusOption, type Order, type OrderItem } from "@fitness-app/data-models/entities/Order";
import { PaymentClient, PaymentTypeOption } from "@fitness-app/data-models/entities/Product";

import InvoiceDataInfo from "~/modules/Products/components/InvoiceDataInfo/InvoiceDataInfo";
import InvoiceNumberInfo from "~/modules/Products/components/InvoiceNumberInfo/InvoiceNumberInfo";
import { exportOrdersToCsv } from "~/modules/Products/Product/ProductOrders/exportOrdersToCsv";
import IssueInvoice from "~/modules/Products/Product/ProductOrders/IssueInvoice";
import { tagColorMapper } from "~/modules/Products/utils/tagColorMapper";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";

interface OwnProps {
  orders?: Order[] | null;
  productId: string;
  ownerId?: string;
}

type Props = OwnProps;

function disabledDate(current: Dayjs) {
  return current && current > dayjs().endOf("month");
}

export const INVOICE_ERROR_FILTER = "error";

const ProductOrders: FunctionComponent<Props> = ({ productId, ownerId }) => {
  const { t } = useTranslation(["products", "common", "client"]);
  const [selectedMonth, setMonth] = useState<Dayjs | null>(dayjs());
  const [searchValue, setSearchValue] = useState("");
  const orders = useSelector(productOrdersSelectors.getFilteredOrders);
  const dispatch = useAppDispatch();
  const { ordersStatus, searchTerm, orders: ordersWithoutFilters } = useAppSelector((store) => store.productOrders);

  useEffect(() => {
    if (selectedMonth) {
      void dispatch(productOrdersActions.fetchOrders({ productId, selectedMonth, ownerId }));
    }
  }, [selectedMonth, productId, dispatch, ownerId]);

  const onClear = () => {
    setSearchValue("");
    dispatch(productOrdersActions.clearSearchMode());
  };

  const onSearch = (value: string) => {
    const trimmed = value.trim();
    if (!trimmed) {
      if (searchTerm) {
        onClear();
      }
      return;
    }
    dispatch(productOrdersActions.setSearchTerm(trimmed));
  };

  return (
    <Card
      title={
        <Space size={16}>
          <span>
            {t("orders.selectMonth", {
              month: selectedMonth ? t(`common:month.${selectedMonth.month() + 1}`) : "-",
            })}
          </span>
          <DatePicker
            picker="month"
            onChange={setMonth}
            style={{ minWidth: 200 }}
            allowClear={false}
            disabledDate={disabledDate}
            value={selectedMonth}
          />
        </Space>
      }
      extra={
        <Button
          type="dashed"
          onClick={() => exportOrdersToCsv(ordersWithoutFilters, productId, selectedMonth?.format("MM-YYYY") ?? "none")}
        >
          {t("orders.exportToCsv")}
        </Button>
      }
    >
      {/*<ProductMonthStatistics />*/}
      <Divider />
      <Space direction="vertical" style={{ width: "100%" }} size={24}>
        <div style={{ width: "100%", display: "flex", alignItems: "center" }}>
          <Input.Search
            placeholder={t<string>("search.orderPlaceholder")}
            onSearch={onSearch}
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            style={{ width: 400, margin: "20px 0" }}
          />
          {Boolean(searchTerm) && (
            <Button onClick={onClear} type="link">
              {t("search.reset")}
            </Button>
          )}
        </div>

        <Table<Order>
          loading={ordersStatus === RequestStatus.SUBSCRIBING}
          rowKey="id"
          dataSource={orders}
          pagination={false}
          scroll={{ x: true }}
          expandedRowRender={(order) => <InvoiceDataInfo orderClient={order.client} />}
        >
          <Table.Column<Order>
            title={t("productTransactions.tableHeader.index")}
            width={50}
            dataIndex="index"
            key="index"
            render={(name, row, i) => i + 1}
          />
          <Table.Column<Order>
            title={t("productTransactions.tableHeader.orderNumber")}
            width={50}
            dataIndex="orderNumber"
            key="orderNumber"
          />
          <Table.Column<Order>
            title={t("productTransactions.tableHeader.name")}
            dataIndex="name"
            key="name"
            render={(_, row) => (row.client.lastName ? `${row.client.lastName} ${row.client.firstName}` : "-")}
            sorter={(a, b) => a.client.lastName?.localeCompare(b.client.lastName)}
          />
          <Table.Column<Order>
            title={t("productTransactions.tableHeader.email")}
            dataIndex="clientEmail"
            key="clientEmail"
            sorter={(a, b) => a.clientEmail.length - b.clientEmail.length}
            render={(email) => <Link to={`/products/${productId}/clients/${email}`}>{email}</Link>}
          />
          <Table.Column<Order>
            title={t("productTransactions.tableHeader.createdAt")}
            dataIndex="createdAt"
            key="createdAt"
            render={(createdAt: string) => dayjs(createdAt).format("DD.MM.YYYY HH:mm")}
            defaultSortOrder="descend"
            sorter={(a, b) => dayjs(a.createdAt).unix() - dayjs(b.createdAt).unix()}
          />
          <Table.Column<Order>
            title={t("productTransactions.tableHeader.price")}
            dataIndex="price"
            key="price"
            render={(price: OrderItem) =>
              price ? (
                <b>
                  {(price.amount ? price.amount / 100 : 0).toFixed(2)} {price.currency?.toUpperCase()}
                </b>
              ) : (
                "-"
              )
            }
            sorter={(a, b) =>
              (!a.price || a.price.free ? 0 : a.price.amount) - (!b.price || b.price.free ? 0 : b.price.amount)
            }
          />
          <Table.Column<Order>
            title={t("productTransactions.tableHeader.status")}
            dataIndex="status"
            key="status"
            filters={Object.values(OrderStatusOption).map((status) => ({
              value: status,
              text: t(`productTransactions.type.${status.toLowerCase()}`),
            }))}
            render={(type: Order["status"]) => (
              <Tag color={tagColorMapper[type || OrderStatusOption.Created]}>
                {t(`productTransactions.type.${type?.toLowerCase() || "paid"}`)}
              </Tag>
            )}
            onFilter={(value, record) => record.status === value}
          />
          <Table.Column<Order>
            title={t("productClients.tableHeader.paymentType")}
            dataIndex="paymentType"
            key="paymentType"
            render={(type, record) => <Tag>{t(`client:type.${record.installments ? "installments" : type}`)}</Tag>}
            filters={[...Object.values(PaymentTypeOption), "installments"].map((type) => ({
              value: type,
              text: t(`client:type.${type}`),
            }))}
            onFilter={(value, record) =>
              Boolean(record.paymentType === value && !record.installments) ||
              Boolean(record.installments && value === "installments")
            }
          />

          <Table.Column<Order>
            title={t("productClients.tableHeader.orderType")}
            dataIndex="orderType"
            key="orderType"
            render={(_type, record) => {
              if (record.paymentCycle && record.paymentCycle > 1) {
                return `Kolejny cykl płatności (${record.paymentCycle})`;
              }

              if (record.metadata?.renewal) {
                return (
                  <Tooltip title={record.metadata.sessionMetadata?.variantSlug} className="whitespace-nowrap">
                    Odnowienie dostępu {record.metadata?.sessionMetadata?.variantSlug ? <InfoCircleOutlined /> : null}
                  </Tooltip>
                );
              }

              return (
                <Tooltip title={record.metadata?.sessionMetadata?.variantSlug} className="whitespace-nowrap">
                  Nowy zakup {record.metadata?.sessionMetadata?.variantSlug ? <InfoCircleOutlined /> : null}
                </Tooltip>
              );
            }}
            filters={[
              {
                text: "Nowy zakup",
                value: "new",
              },
              {
                text: "Odnowienie dostępu",
                value: "renewal",
              },
              {
                text: "Kolejny cykl płatności",
                value: "nextCycle",
              },
            ]}
            onFilter={(value, record) => {
              if (value === "new") {
                return (!record.metadata?.renewal && record.paymentCycle === 1) || !record.paymentCycle;
              }

              if (value === "renewal") {
                return record.metadata?.renewal && record.paymentCycle === 1;
              }

              return record.paymentCycle && record.paymentCycle > 1;
            }}
          />

          <Table.Column<Order>
            title={t("productTransactions.tableHeader.paymentGateway")}
            dataIndex="paymentGateway"
            key="paymentGateway"
            render={(paymentGateway: string, record) =>
              paymentGateway
                ? `${t(`productTransactions.paymentGateway.${paymentGateway.toLowerCase()}`)}${
                    record.paymentMethodType &&
                    record.paymentMethodType !== paymentGateway.toLowerCase() &&
                    record.paymentGateway !== PaymentClient.MANUAL
                      ? ` (${t(`productTransactions.paymentMethodType.${record.paymentMethodType}`)})`
                      : ""
                  }`
                : "-"
            }
          />
          <Table.Column<Order>
            title={t("productTransactions.tableHeader.invoice")}
            dataIndex="connectedInvoice"
            key="connectedInvoice"
            render={(connectedInvoice: Order["connectedInvoice"], order) => (
              <div className="flex w-full items-center justify-between">
                <InvoiceNumberInfo invoice={connectedInvoice} withId />
                {selectedMonth && <IssueInvoice order={order} selectedMonth={selectedMonth} />}
              </div>
            )}
            filters={[
              {
                text: t("common:yes"),
                value: true,
              },
              { text: t("common:no"), value: false },
              { text: t("common:errorInvoices"), value: INVOICE_ERROR_FILTER },
            ]}
            onFilter={(value, record: Order) => {
              if (
                value === INVOICE_ERROR_FILTER &&
                record.connectedInvoice &&
                "errorMessage" in record.connectedInvoice
              ) {
                return true;
              }

              if (
                value === INVOICE_ERROR_FILTER ||
                (record.connectedInvoice && "errorMessage" in record.connectedInvoice)
              ) {
                return false;
              }

              return value === false ? Boolean(!record.connectedInvoice) : Boolean(record.connectedInvoice);
            }}
          />
        </Table>
      </Space>
    </Card>
  );
};

export default ProductOrders;
