import { type FunctionComponent } from "react";
import { Tag } from "antd";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import {
  type OneTimeProductAccessPeriod,
  type SubscriptionProductAccessPeriod,
} from "@fitness-app/data-models/entities/ProductClient";

interface OwnProps {
  accessPeriod: OneTimeProductAccessPeriod | SubscriptionProductAccessPeriod;
  isInstallments?: boolean;
}

type Props = OwnProps;

const InfoAboutExtendedAccessPeriod: FunctionComponent<Props> = ({ accessPeriod, isInstallments }) => {
  const { t } = useTranslation(["client", "products", "common"]);

  if (accessPeriod.trial && !accessPeriod.installments) {
    const distance = Math.abs(
      dayjs.unix(accessPeriod.trial.startAt).diff(dayjs.unix(accessPeriod.trial.endAt).endOf("day"), "day"),
    );

    const label = "subscription.day";
    return (
      <div>
        <Tag color="blue">
          {t(`${accessPeriod.type}.trailingAbout`, { count: distance })}
          <span>{t(label, { count: distance })}</span>
        </Tag>
      </div>
    );
  }

  if (!accessPeriod.pauseCollection || !accessPeriod.currentPeriodEnd || !accessPeriod.pauseCollection.resumes_at) {
    return null;
  }
  let label = "subscription.month";
  let distance = dayjs
    .unix(accessPeriod.pauseCollection.original_access_date)
    .diff(dayjs.unix(accessPeriod.pauseCollection.resumes_at).endOf("day"), "month", true);
  distance = Number(distance.toFixed(1));

  if (distance > 0) {
    distance = dayjs
      .unix(accessPeriod.pauseCollection.original_access_date)
      .diff(dayjs.unix(accessPeriod.pauseCollection.resumes_at).endOf("day"), "day");
    return (
      <div>
        {accessPeriod.pauseCollection.resumes_at && <Tag color="warning">Dostęp został skrócony o {distance} dni</Tag>}
      </div>
    );
  }

  distance = Math.abs(distance);

  if (distance < 1) {
    label = "subscription.day";
    distance = Math.abs(
      dayjs
        .unix(accessPeriod.pauseCollection.original_access_date)
        .diff(dayjs.unix(accessPeriod.pauseCollection.resumes_at).endOf("day"), "day"),
    );
  }

  if (Math.round(distance) !== distance) {
    label = "subscription.day";
    distance = Math.abs(
      dayjs
        .unix(accessPeriod.pauseCollection.original_access_date)
        .diff(dayjs.unix(accessPeriod.pauseCollection.resumes_at).endOf("day"), "day"),
    );
  }

  return (
    <div>
      {accessPeriod.pauseCollection.resumes_at && (
        <Tag color="blue">
          {t(`${accessPeriod.type}.${isInstallments ? "pauseCollectionToInstallments" : "pauseCollectionTo"}`, {
            count: distance,
          })}
          <span>{t(label, { count: distance })}</span>
        </Tag>
      )}
    </div>
  );
};

export default InfoAboutExtendedAccessPeriod;
