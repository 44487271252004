import { useEffect, useState, type FunctionComponent } from "react";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Alert, Button, Col, Form, InputNumber, Radio, Row, Select, Switch } from "antd";
import { type FormInstance } from "antd/lib/form";
import { useTranslation } from "react-i18next";

import { DEFAULT_CURRENCY_OPTIONS, DEFAULT_PRODUCT_CURRENCY } from "@fitness-app/data-models";
import {
  EndAccessStrategy,
  PaymentClient,
  PaymentTypeOption,
  ProductType,
  type PricePaymentType,
  type ProductTypeEnum,
} from "@fitness-app/data-models/entities/Product";

import {
  BillingPeriod,
  createBillingPeriodOptions,
  createEndAccessStrategy,
  createIntervalOptions,
  createNumberOfCycleTypeOptions,
  createPaymentClientsOptions,
  createProductPriceTypeOptions,
} from "~/modules/Products/constants/productForm";
import { type PricesFormModel } from "~/modules/Products/ProductForm/types";

interface OwnProps {
  formController?: FormInstance<PricesFormModel>;
  onSubmit: (formData: PricesFormModel) => void;
  model?: Partial<PricesFormModel> | null;
  productType: ProductTypeEnum;
}

type Props = OwnProps;

const pricesOptions = DEFAULT_CURRENCY_OPTIONS.map((currency) => ({
  value: currency,
  label: currency.toUpperCase(),
}));

const PricesForm: FunctionComponent<Props> = ({ formController, onSubmit, model, productType }: Props) => {
  const { t } = useTranslation(["common", "products"]);
  const [paymentType, changePaymentType] = useState<PricePaymentType[]>([PaymentTypeOption.Recurring]);
  const [selectedBillingPeriod, setBillingPeriod] = useState<string[]>([PaymentTypeOption.Recurring]);
  const [activeInstallments, setActiveInstallments] = useState<Record<string, boolean>>({});
  const prices = Form.useWatch(["prices"], formController);

  const changeBillingPeriodItem = (period: string, index: number) => {
    setBillingPeriod((prev) => {
      const clone = prev.slice();
      clone[index] = period;
      return clone;
    });
  };

  const changePaymentItemType = (type: PricePaymentType, index: number) => {
    changePaymentType((prev) => {
      const clone = prev.slice();
      clone[index] = type;
      return clone;
    });
    setTimeout(() => {
      formController?.setFields([
        {
          name: ["prices", index, "billingPeriod"],
          value: type === PaymentTypeOption.OneTime ? BillingPeriod.ToCancel : BillingPeriod.Monthly,
        },
        ...(type === PaymentTypeOption.OneTime
          ? [
              {
                name: ["prices", index, "endAccessStrategy"] as ["prices", number, "endAccessStrategy"],
                value: EndAccessStrategy.Cancel,
              },
            ]
          : []),
      ]);
    }, 0);
  };

  useEffect(() => {
    if (!model) {
      if ([ProductType.AUTOMATED_CLIENT_SERVICE, ProductType.PERSONAL_CLIENT_SERVICE].includes(productType)) {
        formController?.setFieldsValue({
          prices: [
            {
              currency: DEFAULT_PRODUCT_CURRENCY,
              paymentType: PaymentTypeOption.Recurring,
              billingPeriod: BillingPeriod.Monthly,
              paymentClient: PaymentClient.STRIPE,
            },
          ],
        });
      } else {
        formController?.setFieldsValue({
          prices: [
            {
              currency: DEFAULT_PRODUCT_CURRENCY,
              paymentType: PaymentTypeOption.OneTime,
              paymentClient: PaymentClient.STRIPE,
            },
          ],
        });
      }
    }
  }, [model]);

  return (
    <Form<PricesFormModel>
      name="prices-form"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      layout="horizontal"
      form={formController}
      labelWrap
      initialValues={{
        prices: [],
      }}
      onFinish={onSubmit}
    >
      <Alert showIcon message={t("products:form.pricePaymentInfo")} type="info" style={{ marginBottom: 10 }} />
      <Form.List
        name="prices"
        rules={[
          {
            validator: async (_, levels: string[]) => {
              if (!levels || levels.length < 1) {
                return Promise.reject(new Error("At least 1 level"));
              }
            },
          },
        ]}
      >
        {(fields, { add, remove }) => {
          const onlyOneItem = fields.length === 1;
          return (
            <>
              {fields.map((field, index) => {
                return (
                  <div key={field.key} className="relative mb-6 border border-dashed border-gray-200 bg-gray-50 p-8">
                    <Form.Item
                      name={[field.name, "paymentType"]}
                      tooltip={t<string>("products:form.billingPeriodTooltip")}
                      label={t<string>("products:form.paymentType")}
                      rules={[
                        {
                          required: true,
                          message: t<string>("validationErrors.fieldIsRequired"),
                        },
                      ]}
                    >
                      <Radio.Group onChange={(e) => changePaymentItemType(e.target.value as PricePaymentType, index)}>
                        {createProductPriceTypeOptions(t, productType).map((item) => (
                          <Radio.Button value={item.value} key={item.value}>
                            {item.label}
                          </Radio.Button>
                        ))}
                      </Radio.Group>
                    </Form.Item>
                    <Form.Item label={t<string>("products:form.price")} style={{ marginBottom: 0 }}>
                      <Form.Item
                        name={[field.name, "amount"]}
                        style={{ display: "inline-block" }}
                        rules={[
                          {
                            required: true,
                            type: "number",
                            min: 2,
                            message: t<string>("validationErrors.fieldIsRequired"),
                          },
                        ]}
                      >
                        <InputNumber
                          autoFocus
                          min={0}
                          max={100000}
                          precision={2}
                          name="amount"
                          style={{ minWidth: 150 }}
                        />
                      </Form.Item>

                      <Form.Item name={[field.name, "currency"]} style={{ display: "inline-block", marginLeft: 8 }}>
                        <Select options={pricesOptions} defaultValue={DEFAULT_PRODUCT_CURRENCY} />
                      </Form.Item>
                    </Form.Item>

                    {productType !== ProductType.ONLY_CONTENT && (
                      <>
                        <Form.Item
                          name={[field.name, "billingPeriod"]}
                          label={t<string>(
                            paymentType[index] === PaymentTypeOption.Recurring
                              ? "products:form.billingPeriod"
                              : "products:form.access",
                          )}
                          rules={[
                            {
                              required: paymentType[index] === PaymentTypeOption.Recurring,
                              message: t<string>("validationErrors.fieldIsRequired"),
                            },
                          ]}
                        >
                          <Select
                            style={{ maxWidth: 320 }}
                            onSelect={(value: string) => changeBillingPeriodItem(value, index)}
                          >
                            {createBillingPeriodOptions(t, paymentType[index] ?? PaymentTypeOption.OneTime).map(
                              (period) => (
                                <Select.Option value={period.value} key={period.value}>
                                  {period.label}
                                </Select.Option>
                              ),
                            )}
                          </Select>
                        </Form.Item>

                        {selectedBillingPeriod[index] === BillingPeriod.Custom && (
                          <>
                            <Form.Item label={t<string>("products:form.customInterval")} style={{ marginBottom: 0 }}>
                              <Form.Item
                                name={[field.name, "billingIntervalCount"]}
                                style={{ display: "inline-block" }}
                                rules={[
                                  {
                                    required: true,
                                    type: "number",
                                    min: 1,
                                    message: t<string>("validationErrors.fieldIsRequired"),
                                  },
                                ]}
                              >
                                <InputNumber
                                  min={0}
                                  max={60}
                                  precision={0}
                                  name="numberOfCycles"
                                  style={{ minWidth: 150 }}
                                />
                              </Form.Item>

                              <Form.Item
                                name={[field.name, "billingInterval"]}
                                style={{
                                  display: "inline-block",
                                  marginLeft: 8,
                                }}
                              >
                                <Select options={createIntervalOptions(t)} defaultValue="month" />
                              </Form.Item>
                            </Form.Item>
                          </>
                        )}

                        {paymentType[index] === PaymentTypeOption.Recurring && (
                          <>
                            <Form.Item
                              label={t("products:form.setInstallments")}
                              name={[field.name, "activeInstallments"]}
                              valuePropName="checked"
                            >
                              <Switch
                                onChange={(checked) => setActiveInstallments((prev) => ({ ...prev, [index]: checked }))}
                              />
                            </Form.Item>
                            {activeInstallments[index] ? (
                              <Form.Item
                                name={[field.name, "installments"]}
                                label={t<string>("products:form.installments")}
                                tooltip={t("products:form.installmentsInfo")}
                                rules={[
                                  {
                                    required: true,
                                    message: t<string>("common:validationErrors.fieldIsRequired"),
                                  },
                                ]}
                              >
                                <InputNumber min={0} max={100000} />
                              </Form.Item>
                            ) : null}

                            {activeInstallments[index] && (
                              <Form.Item
                                label="Czas dostępu niepowiązany z okresem rat"
                                name={[field.name, "activeCustomDuration"]}
                                valuePropName="checked"
                              >
                                <Switch />
                              </Form.Item>
                            )}

                            {prices[index]?.activeCustomDuration && (
                              <>
                                <Form.Item
                                  label={t<string>("products:form.productDurationCycle")}
                                  style={{ marginBottom: 0 }}
                                >
                                  <Form.Item
                                    name={[field.name, "customDuration", "count"]}
                                    style={{ display: "inline-block" }}
                                    rules={[
                                      {
                                        required: true,
                                        type: "number",
                                        min: 1,
                                        message: t<string>("validationErrors.fieldIsRequired"),
                                      },
                                    ]}
                                  >
                                    <InputNumber autoFocus min={0} max={60} precision={0} style={{ minWidth: 150 }} />
                                  </Form.Item>

                                  <Form.Item
                                    name={[field.name, "customDuration", "unit"]}
                                    style={{ display: "inline-block", marginLeft: 8 }}
                                    rules={[
                                      {
                                        required: true,
                                        message: t<string>("validationErrors.fieldIsRequired"),
                                      },
                                    ]}
                                  >
                                    <Select options={createNumberOfCycleTypeOptions(t)} placeholder="Wybierz okres" />
                                  </Form.Item>
                                </Form.Item>
                              </>
                            )}
                          </>
                        )}

                        {paymentType[index] === PaymentTypeOption.OneTime ? (
                          <Form.Item
                            name={[field.name, "endAccessStrategy"]}
                            label={t<string>("products:form.endAccessStrategy")}
                            rules={[
                              {
                                required: true,
                                message: t<string>("validationErrors.fieldIsRequired"),
                              },
                            ]}
                          >
                            <Select style={{ maxWidth: 320 }}>
                              {createEndAccessStrategy(t).map((strategy) => (
                                <Select.Option value={strategy.value} key={strategy.value}>
                                  {strategy.label}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        ) : null}
                      </>
                    )}

                    <Form.Item
                      name={[field.name, "paymentClient"]}
                      label={t<string>("products:form.paymentClient")}
                      rules={[
                        {
                          required: true,
                          message: t<string>("validationErrors.fieldIsRequired"),
                        },
                      ]}
                    >
                      <Select style={{ maxWidth: 250 }} disabled>
                        {createPaymentClientsOptions(t).map((client) => (
                          <Select.Option value={client.value} key={client.value}>
                            {client.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>

                    {!onlyOneItem && (
                      <div className="absolute right-6 top-6">
                        <DeleteOutlined
                          style={{ color: "#f64e60", fontSize: 20 }}
                          onClick={() => {
                            changePaymentType((prev) => prev.filter((_, i) => i !== index));
                            remove(field.name);
                          }}
                        />
                      </div>
                    )}
                  </div>
                );
              })}
              <div
                style={{
                  flexBasis: "100%",
                  marginTop: 16,
                  opacity: paymentType ? 1 : 0,
                }}
              >
                <Row style={{ width: "100%" }}>
                  <Col xs={12} offset={8}>
                    <Button
                      type="primary"
                      onClick={() => {
                        changePaymentType((value) => [...value, PaymentTypeOption.Recurring]);
                        add({
                          currency: DEFAULT_PRODUCT_CURRENCY,
                          billingPeriod: BillingPeriod.Monthly,
                          paymentType: PaymentTypeOption.Recurring,
                          paymentClient: PaymentClient.STRIPE,
                        });
                      }}
                      block
                      icon={<PlusOutlined />}
                    >
                      {fields.length ? t<string>("products:form.addAnotherPrice") : t<string>("products:form.addPrice")}
                    </Button>
                  </Col>
                </Row>
              </div>
            </>
          );
        }}
      </Form.List>
    </Form>
  );
};

export default PricesForm;
