import {
  type DietaryPreference,
  type MacroSplit,
  type MealsPlanDetails,
  type MealsPlanWithCreator,
} from "@fitness-app/data-models/entities/MealsPlan";

import { type RequestStatus } from "../../../enums/requestStatus";

export interface MealsPlansReducer {
  list: MealsPlanWithCreator[];
  listStatus: null | RequestStatus;
  listPerPage: number;
  listPage: number;
  listTotalPages: number;
  selectedMealsPlan: MealsPlanWithCreator | null;
  selectedMealsPlanStatus: null | RequestStatus;
  selectedMealsPlanDetails: MealsPlanDetails[] | null;
  searchTerm: string | null;
  filters: {
    currentAssignedTrainerFilter: string | null;
    currentFilteredTags: string[];
    caloriesFilter: {
      type: "greater" | "less";
      value: number;
    } | null;
    dietaryPreference: DietaryPreference | null;
    macroSplit: MacroSplit | null;
  };
}

export const MEALS_PLANS_REDUCER_NAME = "mealsPlans";
