import { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";

import {
  CheckoutVariantStatus,
  CheckoutVariantTarget,
  type CheckoutVariant,
} from "@fitness-app/data-models/entities/CheckoutVariant";
import { getProductLink } from "@fitness-app/utils/src/products/getProductLink";

import { supabase } from "~/store/initializeStore";

export const useGenerateRenewalLink = (productId: string, email: string) => {
  const { data, isLoading } = useQuery(["checkoutVariants", productId], {
    queryFn: async () => {
      const { data, error } = await supabase
        .from("checkout_variant")
        .select("*")
        .in("status", [CheckoutVariantStatus.Active])
        .in("target", [CheckoutVariantTarget.Renewal, CheckoutVariantTarget.All])
        .eq("productId", productId)
        .eq("excluded", false)
        .order("createdAt", { ascending: false })
        .limit(100)
        .returns<CheckoutVariant[]>();

      if (error) {
        throw new Error(error.message);
      }

      if (!data) {
        return [];
      }

      return data;
    },
  });

  const getVariantLink = (email: string) => {
    if (!data?.length) {
      return null;
    }

    const forRenewalDefault = data.find(
      (variant) => variant.target === CheckoutVariantTarget.Renewal && variant.default,
    );
    const forRenewal = data.find((variant) => variant.target === CheckoutVariantTarget.Renewal);

    if (forRenewalDefault || forRenewal) {
      const link = getProductLink({
        productId,
        domain: import.meta.env.VITE_ECOMMERCE_DOMAIN,
        slug: forRenewalDefault?.slug || forRenewal?.slug,
        isVariant: true,
        isRenewal: true,
        email,
      });
      return { link, slug: forRenewalDefault?.slug || forRenewal?.slug || "slug" };
    } else if (data.length) {
      const defaultVariant = data.find((variant) => variant.default);

      const link = getProductLink({
        productId,
        domain: import.meta.env.VITE_ECOMMERCE_DOMAIN,
        slug: defaultVariant?.slug || data[0]?.slug,
        isVariant: true,
        email,
      });
      return { link, slug: defaultVariant?.slug || defaultVariant?.slug || "slug" };
    }
    return null;
  };

  const restLinkWithoutDefault = useMemo(() => {
    const renewalVariants =
      data?.filter((variant) => variant.target === CheckoutVariantTarget.Renewal && !variant.default) || [];

    return renewalVariants.map((variant) => {
      const link = getProductLink({
        productId,
        domain: import.meta.env.VITE_ECOMMERCE_DOMAIN,
        slug: variant.slug,
        isVariant: true,
        isRenewal: true,
        email,
      });
      return { link, slug: variant.slug };
    });
  }, [data, email]);

  return {
    data,
    isLoading,
    getVariantLink,
    restLinkWithoutDefault,
  };
};
