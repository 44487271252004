import { useEffect, type FunctionComponent } from "react";
import { CloseOutlined, DeleteOutlined, MailOutlined, MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Alert,
  AutoComplete,
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
  Space,
  Switch,
  TreeSelect,
} from "antd";
import { type FormInstance } from "antd/lib/form";
import { useTranslation } from "react-i18next";

import {
  AppAccessType,
  AutomationStartPoint,
  DiscordIntegrationTask,
  EmailClientTask,
  FitnessAppTask,
  ProductTask,
} from "@fitness-app/data-models/entities/Automation";
import { ProductType } from "@fitness-app/data-models/entities/Product";
import { EmailClient } from "@fitness-app/data-models/entities/ProductsSettings";

import { useEmailLists } from "~/hooks/useEmailLists";
import { useTagOptions } from "~/hooks/useTagOptions";
import { useFitnessAppAutomationTask } from "~/modules/Products/hooks/useFitnessAppAutomationTask";
import { useProductPricesOptions } from "~/modules/Products/hooks/useProductPricesOptions";
import { useProductTaskAutomation } from "~/modules/Products/hooks/useProductTaskAutomation";
import {
  type AddClientToFitnessApp,
  type AddProgramAutomationToClient,
  type AutomationTaskFormModel,
  type ImportIntoProductTask,
} from "~/modules/Products/Product/ProductAutomation/components/AutomationTaskForm/types";
import { useDiscordIntegration } from "~/modules/Products/Product/ProductAutomation/hooks/useDiscordIntegration";
import { createTriggerOptions } from "~/modules/Products/Product/ProductAutomation/triggers";
import { useAppSelector } from "~/store/initializeStore";

interface OwnProps {
  formController?: FormInstance<AutomationTaskFormModel>;
  onSubmit: (formData: AutomationTaskFormModel) => void;
  model?: AutomationTaskFormModel | null;
}

type Props = OwnProps;

const AutomationTaskForm: FunctionComponent<Props> = ({ formController, onSubmit, model }) => {
  const { t } = useTranslation(["products", "common"]);
  const productDetails = useAppSelector((store) => store.product.details);
  const selectedProgramAutomation = Form.useWatch(
    ["action", "programAutomation"],
    formController,
  ) as AddProgramAutomationToClient["programAutomation"];
  const selectedTaskType = Form.useWatch(["action", "type"], formController);
  const sendReminderAboutAccess = Form.useWatch(
    ["action", "sendReminderAboutAccess"],
    formController,
  ) as AddClientToFitnessApp["sendReminderAboutAccess"];
  const { productAutomationTaskOptions, productWithPrices, productsById } = useProductTaskAutomation(
    productDetails?.id,
  );
  const { options: traineeTags } = useTagOptions("traineeTags");
  const { lists, listOrAccountOptions, emailAutomationTaskOptions, tags } = useEmailLists();
  const { discordAutomationTaskOptions, discordIntegration } = useDiscordIntegration();
  const { fitnessAppTaskOptions, programAutomationOptions, appAccessOptions } =
    useFitnessAppAutomationTask(productDetails);
  const productPricesOptions = useProductPricesOptions();

  const selectedProducts = Form.useWatch(["action", "products"], formController) as ImportIntoProductTask["products"];
  const automationFilters = Form.useWatch("automationFilters", formController);
  const includedPrices = Form.useWatch("includedPrices", formController);
  const excludedPrices = Form.useWatch("excludedPrices", formController);

  useEffect(() => {
    if (model) {
      formController?.setFieldsValue({
        ...model,
        includedPrices: model.includedPrices || [],
        excludedPrices: model.excludedPrices || [],
      });
    } else {
      formController?.resetFields();
    }
  }, [model]);

  useEffect(() => {
    if (selectedTaskType === FitnessAppTask.AddClientToApp) {
      formController?.setFields([
        {
          name: ["action", "accessType"],
          value:
            productDetails?.type === ProductType.AUTOMATED_CLIENT_SERVICE
              ? AppAccessType.Limited
              : AppAccessType.FullAccess,
        },
      ]);
    }
  }, [selectedTaskType]);

  const renderTaskAction = (
    taskFromParam?: FitnessAppTask | ProductTask | EmailClientTask | DiscordIntegrationTask,
    namePrefix?: string | number,
    fieldKeyObj?: { fieldKey?: number | undefined },
  ) => {
    const selectedTask = taskFromParam || selectedTaskType;
    if (selectedTask === ProductTask.RemoveFromProduct) {
      return (
        <>
          <Col offset={8}>
            <Alert
              message={t("products:productAutomation.form.infoAboutRemovingFromProduct")}
              style={{ marginTop: 10, marginBottom: 10 }}
            />
          </Col>

          <Form.Item
            label={t<string>("products:productAutomation.form.removeFromProduct")}
            rules={[
              {
                required: true,
                message: t<string>("common:validationErrors.fieldIsRequired"),
              },
            ]}
          >
            <Form.List
              name={
                typeof namePrefix === "number" || typeof namePrefix === "string"
                  ? [namePrefix, "action", "products"]
                  : ["action", "products"]
              }
            >
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <div key={key} style={{ marginBottom: 8, width: "100%" }}>
                      <Space style={{ width: "100%" }} align="baseline">
                        <Form.Item
                          {...restField}
                          name={[name, "productId"]}
                          style={{ flex: 1, width: 350, marginBottom: 5 }}
                          rules={[
                            {
                              required: true,
                              message: t<string>("common:validationErrors.fieldIsRequired"),
                            },
                          ]}
                        >
                          <Select
                            options={productWithPrices.map((product) => ({
                              value: product.id,
                              label: product.name,
                            }))}
                            filterOption={(input, option) => {
                              return (option?.label as unknown as string).toLowerCase().includes(input.toLowerCase());
                            }}
                            autoFocus
                            showSearch
                            popupMatchSelectWidth={false}
                            placeholder={t("products:productAutomation.form.selectProductForImport")}
                          />
                        </Form.Item>
                        {selectedProducts?.length > 1 && <MinusCircleOutlined onClick={() => remove(name)} />}
                      </Space>
                    </div>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() =>
                        add({
                          productId: "",
                          priceId: null,
                        })
                      }
                      block
                      icon={<PlusOutlined />}
                    >
                      {t("productAutomation.form.addProduct")}
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Form.Item>
          <Form.Item
            label={t<string>("products:productAutomation.form.cancelActiveSubscription")}
            name={["action", "cancelActiveSubscription"]}
            valuePropName="checked"
            tooltip={t<string>("products:productAutomation.form.cancelActiveSubscriptionTooltip")}
          >
            <Switch />
          </Form.Item>
        </>
      );
    }
    if (selectedTask === ProductTask.ImportIntoProduct) {
      return (
        <>
          <Form.Item
            label={t<string>("products:productAutomation.form.importIntoProducts")}
            rules={[
              {
                required: true,
                message: t<string>("common:validationErrors.fieldIsRequired"),
              },
            ]}
          >
            <Form.List
              name={
                typeof namePrefix === "number" || typeof namePrefix === "string"
                  ? [namePrefix, "products"]
                  : ["action", "products"]
              }
            >
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => {
                    const productId = selectedProducts[key]?.productId;
                    return (
                      <div key={key} style={{ marginBottom: 8, width: "100%" }}>
                        <Space style={{ width: "100%" }} align="baseline">
                          <Form.Item
                            {...restField}
                            name={[name, "productId"]}
                            style={{ flex: 1, width: 220, marginBottom: 5 }}
                            rules={[
                              {
                                required: true,
                                message: t<string>("common:validationErrors.fieldIsRequired"),
                              },
                            ]}
                          >
                            <Select
                              options={productWithPrices.map((product) => ({
                                value: product.id,
                                label: product.name,
                              }))}
                              filterOption={(input, option) => {
                                return (option?.label as unknown as string).toLowerCase().includes(input.toLowerCase());
                              }}
                              autoFocus
                              showSearch
                              popupMatchSelectWidth={false}
                              placeholder={t("products:productAutomation.form.selectProductForImport")}
                            />
                          </Form.Item>
                          {productId && productsById[productId] && !productsById[productId]?.isFree ? (
                            <Form.Item
                              {...restField}
                              style={{ flex: 1, width: 200, marginBottom: 5 }}
                              name={[name, "priceId"]}
                              rules={[
                                {
                                  required: true,
                                  message: t<string>("common:validationErrors.fieldIsRequired"),
                                },
                              ]}
                            >
                              <Select
                                popupMatchSelectWidth={false}
                                options={productsById[productId]?.prices?.map((price) => ({
                                  value: price.value,
                                  label: price.label,
                                }))}
                                placeholder={t("products:productAutomation.form.selectProductPriceForImport")}
                              />
                            </Form.Item>
                          ) : null}

                          {selectedProducts?.length > 1 && <MinusCircleOutlined onClick={() => remove(name)} />}
                        </Space>
                      </div>
                    );
                  })}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() =>
                        add({
                          productId: "",
                          priceId: null,
                        })
                      }
                      block
                      icon={<PlusOutlined />}
                    >
                      {t("productAutomation.form.addProduct")}
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Form.Item>
          <Form.Item
            label={t<string>("products:productAutomation.form.sendInfoAboutAddToProduct")}
            name={["action", "sendEmailAboutAddToProduct"]}
            valuePropName="checked"
          >
            <Switch checkedChildren={<MailOutlined />} unCheckedChildren={<CloseOutlined />} />
          </Form.Item>
        </>
      );
    }

    if (selectedTask === FitnessAppTask.AddClientToApp) {
      return (
        <>
          <Form.Item
            name={["action", "accessType"]}
            label={t<string>("productAutomation.form.accessType")}
            rules={[
              {
                required: true,
                message: t<string>("common:validationErrors.fieldIsRequired"),
              },
            ]}
          >
            <Select disabled={Boolean(model)} options={appAccessOptions} />
          </Form.Item>
          <Form.Item
            name={["action", "programAutomation"]}
            label={t<string>("productAutomation.form.programAutomation")}
          >
            <Select
              allowClear
              options={programAutomationOptions}
              placeholder={t("productAutomation.form.selectAutomationProgram")}
            />
          </Form.Item>

          {Boolean(selectedProgramAutomation) && (
            <Form.Item
              name={["action", "startAutomationFrom"]}
              label={t<string>("productAutomation.form.startAutomationFrom.label")}
            >
              <Radio.Group>
                <Space direction="vertical">
                  <Radio value={AutomationStartPoint.AfterPurchase}>
                    {t(`productAutomation.form.startAutomationFrom.${AutomationStartPoint.AfterPurchase}`)}
                  </Radio>
                  <Radio value={AutomationStartPoint.StartOfWeek}>
                    {t(`productAutomation.form.startAutomationFrom.${AutomationStartPoint.StartOfWeek}`)}
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          )}

          <Form.Item name={["action", "tags"]} label={t<string>("productAutomation.form.clientTags")}>
            <Select
              notFoundContent={false}
              disabled={model?.protected}
              options={[...traineeTags, ...tags]}
              mode="tags"
              placeholder={t<string>("products:form.clientTagsPlaceholder")}
            />
          </Form.Item>

          <Form.Item label={t<string>("products:productAutomation.form.sendReminderAboutAccess")}>
            <Form.List name={["action", "sendReminderAboutAccess"]}>
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => {
                    return (
                      <div key={key} style={{ marginBottom: 8, width: "100%" }}>
                        <Space style={{ width: "100%" }} align="baseline">
                          <Form.Item
                            {...restField}
                            name={[name, "afterDays"]}
                            rules={[
                              {
                                required: true,
                                message: t<string>("common:validationErrors.fieldIsRequired"),
                              },
                            ]}
                          >
                            <InputNumber
                              style={{ maxWidth: 150 }}
                              placeholder={t<string>("products:productAutomation.form.daysPlaceholder")}
                              addonAfter={t("products:productAutomation.form.days")}
                            />
                          </Form.Item>
                          <DeleteOutlined
                            onClick={() => remove(name)}
                            style={{ fontSize: 18, color: "#f64e60", verticalAlign: "text-top", paddingTop: 4 }}
                          />
                        </Space>

                        <Form.List
                          name={[name, "actions"]}
                          initialValue={[{ type: EmailClientTask.AddToLists, emailLists: [] }]}
                        >
                          {(fields, { add, remove }) => (
                            <>
                              {fields.map(({ key, name: nestedName, ...restField }) => {
                                const selectedAction = sendReminderAboutAccess?.[name]?.actions?.[nestedName];

                                return (
                                  <div key={key} className="mb-2 w-full rounded-md bg-gray-100/50 p-2">
                                    <Form.Item
                                      {...restField}
                                      name={[nestedName, "type"]}
                                      label={t<string>("productAutomation.form.selectTaskType")}
                                      rules={[
                                        {
                                          required: true,
                                          message: t<string>("common:validationErrors.fieldIsRequired"),
                                        },
                                      ]}
                                    >
                                      <Select
                                        placeholder={t<string>("productAutomation.form.selectTaskType")}
                                        options={[...emailAutomationTaskOptions, ...discordAutomationTaskOptions]}
                                      />
                                    </Form.Item>

                                    {selectedAction ? renderTaskAction(selectedAction.type, nestedName) : null}

                                    <div className="text-right">
                                      <MinusCircleOutlined
                                        style={{ color: "#f64e60", fontSize: 16 }}
                                        onClick={() => remove(nestedName)}
                                      />
                                    </div>
                                  </div>
                                );
                              })}
                              <Form.Item>
                                <Button
                                  type="dashed"
                                  onClick={() =>
                                    add({
                                      type: EmailClientTask.AddToLists,
                                    })
                                  }
                                  block
                                  icon={<PlusOutlined />}
                                >
                                  {t("productAutomation.form.addAction")}
                                </Button>
                              </Form.Item>
                            </>
                          )}
                        </Form.List>
                      </div>
                    );
                  })}
                  <Form.Item>
                    <Button
                      onClick={() =>
                        add({
                          afterDays: 1,
                          actions: [{ type: EmailClientTask.AddTags, tags: [], emailClients: [] }],
                        })
                      }
                      block
                      icon={<PlusOutlined />}
                    >
                      {t("productAutomation.form.addReminder")}
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Form.Item>
        </>
      );
    }

    if (selectedTask === FitnessAppTask.AddProgramAutomationToClient) {
      return (
        <>
          <Form.Item
            name={["action", "programAutomation"]}
            label={t<string>("productAutomation.form.programAutomation")}
            rules={[
              {
                required: true,
                message: t<string>("common:validationErrors.fieldIsRequired"),
              },
            ]}
          >
            <Select
              allowClear
              options={programAutomationOptions}
              placeholder={t("productAutomation.form.selectAutomationProgram")}
            />
          </Form.Item>

          {Boolean(selectedProgramAutomation) && (
            <Form.Item
              name={["action", "startAutomationFrom"]}
              label={t<string>("productAutomation.form.startAutomationFrom.label")}
            >
              <Radio.Group>
                <Space direction="vertical">
                  <Radio value={AutomationStartPoint.AfterPurchase}>
                    {t(`productAutomation.form.startAutomationFrom.${AutomationStartPoint.AfterPurchase}`)}
                  </Radio>
                  <Radio value={AutomationStartPoint.StartOfWeek}>
                    {t(`productAutomation.form.startAutomationFrom.${AutomationStartPoint.StartOfWeek}`)}
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          )}
        </>
      );
    }

    if (
      selectedTask === FitnessAppTask.AddTagsToTraineeProfile ||
      selectedTask === FitnessAppTask.RemoveTagsFromTraineeProfile
    ) {
      return (
        <>
          <Form.Item name={["action", "tags"]} label={t<string>("productAutomation.form.clientTags")}>
            <Select
              notFoundContent={false}
              disabled={model?.protected}
              options={[...traineeTags, ...tags]}
              mode="tags"
              placeholder={t<string>("products:form.clientTagsPlaceholder")}
            />
          </Form.Item>
        </>
      );
    }

    if (
      selectedTask === DiscordIntegrationTask.RemoveFromServer ||
      selectedTask === DiscordIntegrationTask.AddToServer
    ) {
      return (
        <>
          <Form.Item
            {...fieldKeyObj}
            name={
              typeof namePrefix === "number" || typeof namePrefix === "string"
                ? [namePrefix, "serverId"]
                : ["action", "serverId"]
            }
            label={t<string>("products:form.discordServerId")}
            rules={[
              {
                required: true,
                message: t<string>("common:validationErrors.fieldIsRequired"),
              },
            ]}
          >
            <Select
              placeholder={t("products:form.selectDiscordAccount")}
              notFoundContent={false}
              optionFilterProp="title"
              optionLabelProp="title"
              allowClear
            >
              {discordIntegration.map((account) => (
                <Select.Option value={account.guildId} title={account.guild.name} key={account.guildId}>
                  {account.guild.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </>
      );
    }
    if (selectedTask === EmailClientTask.AddTags) {
      return (
        <>
          <Form.Item
            {...fieldKeyObj}
            name={
              typeof namePrefix === "number" || typeof namePrefix === "string"
                ? [namePrefix, "emailClients"]
                : ["action", "emailClients"]
            }
            label={t<string>("products:form.emailClients")}
            rules={[
              {
                required: true,
                message: t<string>("common:validationErrors.fieldIsRequired"),
              },
            ]}
          >
            <TreeSelect
              disabled={model?.protected}
              style={{ maxWidth: 700 }}
              treeData={listOrAccountOptions.filter((account) => account.value !== EmailClient.FreshMail)}
              showCheckedStrategy={TreeSelect.SHOW_CHILD}
              treeCheckable
              treeNodeLabelProp="title"
            />
          </Form.Item>

          <Col offset={8}>
            <Alert message={t("products:form.emailClientTagsInfo")} style={{ marginTop: 10, marginBottom: 10 }} />
          </Col>

          <Form.Item
            {...fieldKeyObj}
            name={
              typeof namePrefix === "number" || typeof namePrefix === "string"
                ? [namePrefix, "tags"]
                : ["action", "tags"]
            }
            label={t<string>("products:form.clientTags")}
            rules={[
              {
                required: true,
                message: t<string>("common:validationErrors.fieldIsRequired"),
              },
            ]}
          >
            <Select
              notFoundContent={false}
              disabled={model?.protected}
              options={tags}
              mode="tags"
              placeholder={t<string>("products:form.clientTagsPlaceholder")}
            />
          </Form.Item>
        </>
      );
    }

    if (selectedTask === EmailClientTask.RemoveTags) {
      return (
        <>
          <Form.Item
            {...fieldKeyObj}
            name={
              typeof namePrefix === "number" || typeof namePrefix === "string"
                ? [namePrefix, "emailClients"]
                : ["action", "emailClients"]
            }
            label={t<string>("products:form.emailClients")}
            rules={[
              {
                required: true,
                message: t<string>("common:validationErrors.fieldIsRequired"),
              },
            ]}
          >
            <TreeSelect
              disabled={model?.protected}
              style={{ maxWidth: 700 }}
              treeData={listOrAccountOptions.filter((account) => account.value !== EmailClient.FreshMail)}
              showCheckedStrategy={TreeSelect.SHOW_CHILD}
              treeCheckable
              treeNodeLabelProp="title"
            />
          </Form.Item>

          <Col offset={8}>
            <Alert message={t("products:form.emailClientTagsInfo")} style={{ marginTop: 10, marginBottom: 10 }} />
          </Col>

          <Form.Item
            {...fieldKeyObj}
            tooltip={t<string>("products:form.clientTagsAdditional")}
            name={
              typeof namePrefix === "number" || typeof namePrefix === "string"
                ? [namePrefix, "tags"]
                : ["action", "tags"]
            }
            label={t<string>("products:form.clientTags")}
            rules={[
              {
                required: true,
                message: t<string>("common:validationErrors.fieldIsRequired"),
              },
            ]}
          >
            <Select
              notFoundContent={false}
              disabled={model?.protected}
              options={tags}
              mode="tags"
              placeholder={t<string>("products:form.clientTagsPlaceholder")}
            />
          </Form.Item>
        </>
      );
    }

    if (selectedTask === EmailClientTask.AddToLists) {
      return (
        <Form.Item
          {...fieldKeyObj}
          name={
            typeof namePrefix === "number" || typeof namePrefix === "string"
              ? [namePrefix, "emailLists"]
              : ["action", "emailLists"]
          }
          label={t<string>("products:form.emailLists")}
          rules={[
            {
              required: true,
              message: t<string>("common:validationErrors.fieldIsRequired"),
            },
          ]}
        >
          <TreeSelect
            style={{ maxWidth: 700 }}
            treeData={lists}
            disabled={model?.protected}
            showCheckedStrategy={TreeSelect.SHOW_CHILD}
            treeCheckable
            treeNodeLabelProp="title"
          />
        </Form.Item>
      );
    }

    if (selectedTask === EmailClientTask.AddFieldValues) {
      return (
        <>
          <Form.Item
            {...fieldKeyObj}
            tooltip={t<string>("products:form.emailListsInfo")}
            name={
              typeof namePrefix === "number" || typeof namePrefix === "string"
                ? [namePrefix, "emailLists"]
                : ["action", "emailLists"]
            }
            label={t<string>("products:productAutomation.form.emailListsOrAccount")}
            rules={[
              {
                required: true,
                message: t<string>("common:validationErrors.fieldIsRequired"),
              },
            ]}
          >
            <TreeSelect
              style={{ maxWidth: 700 }}
              treeData={listOrAccountOptions}
              disabled={model?.protected}
              showCheckedStrategy={TreeSelect.SHOW_CHILD}
              treeCheckable
              treeNodeLabelProp="title"
            />
          </Form.Item>
          <Form.Item
            {...fieldKeyObj}
            label={t("productAutomation.form.fieldValues")}
            tooltip={t("productAutomation.form.fieldValuesTooltip")}
          >
            <Form.List
              name={
                typeof namePrefix === "number" || typeof namePrefix === "string"
                  ? [namePrefix, "values"]
                  : ["action", "values"]
              }
              initialValue={[{ key: "", value: "" }]}
            >
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <div key={key} style={{ marginBottom: 8, width: "100%" }}>
                      <Space style={{ width: "100%" }} align="baseline">
                        <Form.Item
                          {...restField}
                          name={[name, "key"]}
                          style={{ flex: 1, width: 220, marginBottom: 5 }}
                          rules={[
                            {
                              required: true,
                              message: t<string>("common:validationErrors.fieldIsRequired"),
                            },
                          ]}
                        >
                          <Input
                            disabled={model?.protected}
                            placeholder={t<string>("productAutomation.form.bodyPlaceholder")}
                          />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          style={{ flex: 1, width: 235, marginBottom: 5 }}
                          name={[name, "value"]}
                          rules={[
                            {
                              required: true,
                              message: t<string>("common:validationErrors.fieldIsRequired"),
                            },
                          ]}
                        >
                          <AutoComplete
                            options={[]}
                            disabled={model?.protected}
                            placeholder={t("productAutomation.form.valuePlaceholder")}
                          />
                        </Form.Item>
                        {fields.length > 1 ? <MinusCircleOutlined onClick={() => remove(name)} /> : null}
                      </Space>
                    </div>
                  ))}
                  {!model?.protected && (
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() =>
                          add({
                            key: "",
                            value: "",
                          })
                        }
                        block
                        icon={<PlusOutlined />}
                      >
                        {t("productAutomation.form.addField")}
                      </Button>
                    </Form.Item>
                  )}
                </>
              )}
            </Form.List>
          </Form.Item>
        </>
      );
    }

    if (selectedTask === EmailClientTask.RemoveFromLists) {
      return (
        <>
          <Form.Item
            {...fieldKeyObj}
            tooltip={t<string>("products:form.emailListsInfo")}
            name={
              typeof namePrefix === "number" || typeof namePrefix === "string"
                ? [namePrefix, "emailLists"]
                : ["action", "emailLists"]
            }
            label={t<string>("products:form.emailLists")}
            rules={[
              {
                required: true,
                message: t<string>("validationErrors.fieldIsRequired"),
              },
            ]}
          >
            <TreeSelect
              style={{ maxWidth: 700 }}
              treeData={lists}
              disabled={model?.protected}
              showCheckedStrategy={TreeSelect.SHOW_CHILD}
              treeCheckable
              treeNodeLabelProp="title"
            />
          </Form.Item>
        </>
      );
    }

    return null;
  };

  return (
    <div>
      <Form<AutomationTaskFormModel>
        name="automation-task-form"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        form={formController}
        labelWrap
        initialValues={{
          active: true,
          description: "",
          automationFilters: model?.includedPrices?.length || model?.excludedPrices?.length,
          prices: [productPricesOptions[0]],
          ...model,
        }}
        onFinish={onSubmit}
      >
        <Form.Item
          name={["action", "type"]}
          label={t<string>("productAutomation.form.selectTaskType")}
          rules={[
            {
              required: true,
              message: t<string>("common:validationErrors.fieldIsRequired"),
            },
          ]}
        >
          <Select
            disabled={model?.protected || Boolean(model)}
            placeholder={t<string>("productAutomation.form.selectTaskType")}
            options={[
              ...fitnessAppTaskOptions,
              ...emailAutomationTaskOptions,
              ...productAutomationTaskOptions,
              ...discordAutomationTaskOptions,
            ]}
          />
        </Form.Item>

        {renderTaskAction()}

        <Form.Item name="description" label={t<string>("productAutomation.form.description")}>
          <Input.TextArea rows={3} placeholder={t<string>("productAutomation.form.descriptionTaskPlaceholder")} />
        </Form.Item>

        <Form.Item
          name="triggers"
          label={t<string>("productAutomation.form.triggers")}
          tooltip={t<string>("productAutomation.form.triggersPlaceholder")}
          rules={[
            {
              required: true,
              message: t<string>("common:validationErrors.fieldIsRequired"),
            },
          ]}
        >
          <Select
            disabled={model?.protected}
            mode="multiple"
            options={createTriggerOptions(t, productDetails?.type, productDetails?.chargeType)}
          />
        </Form.Item>

        {productPricesOptions.length ? (
          <>
            <Form.Item
              name="automationFilters"
              valuePropName="checked"
              label={t<string>("productAutomation.form.automationFilters")}
            >
              <Switch />
            </Form.Item>
            {automationFilters && (
              <>
                <Form.Item
                  name="includedPrices"
                  label={t<string>("products:productAutomation.form.includedPrices")}
                  tooltip={t<string>("productAutomation.form.includedPricesTriggers")}
                >
                  <Select mode="multiple" options={productPricesOptions} disabled={Boolean(excludedPrices?.length)} />
                </Form.Item>
                <Form.Item
                  name="excludedPrices"
                  label={t<string>("products:productAutomation.form.excludedPrices")}
                  tooltip={t<string>("productAutomation.form.excludedPricesTriggers")}
                >
                  <Select mode="multiple" options={productPricesOptions} disabled={Boolean(includedPrices?.length)} />
                </Form.Item>
              </>
            )}
          </>
        ) : null}

        <Form.Item name="active" valuePropName="checked" label={t<string>("productAutomation.form.active")}>
          <Switch />
        </Form.Item>
      </Form>
    </div>
  );
};

export default AutomationTaskForm;
