import { type ChatMember } from "@fitness-app/data-models/entities/Chat";

export const getMemberName = (
  member?: ChatMember,
  profilesById?: Record<
    string,
    { email: string; firstName: string | null; lastName: string | null; avatarUrl: string | null }
  > | null,
) => {
  if (!member) {
    return "";
  }

  const profile = member.uid && profilesById?.[member.uid];
  if (profile && profile.firstName) {
    return `${profile.firstName} ${profile.lastName}`.trim();
  }

  return member.name;
};

export const getChatMemberName = (member?: ChatMember & { firstName?: string | null; lastName?: string | null }) => {
  if (!member) {
    return undefined;
  }

  if (member.firstName && member.lastName) {
    return `${member.firstName} ${member.lastName}`.trim();
  }

  return member.name;
};
