import { useState, type FunctionComponent } from "react";
import { LinkOutlined } from "@ant-design/icons";
import { Alert, Button, Card, Descriptions, Grid, message, Space, Switch, Tabs, Tooltip, Typography } from "antd";
import dayjs from "dayjs";
import truncate from "lodash.truncate";
import { useTranslation } from "react-i18next";

import { productActions, RequestStatus } from "@fitness-app/app-store";
import {
  EndAccessStrategy,
  PaymentTypeOption,
  ProductChargeType,
  ProductDurationType,
  ProductPriceAction,
  ProductStatus,
  ProductType,
  type EndAccessOffers,
  type InvoicesClient,
  type OneTimePrice,
  type RecurringInterval,
  type RecurringPrice,
  type UpdateProductDTO,
  type UpdateProductPriceDTO,
  type UpsellTo,
} from "@fitness-app/data-models/entities/Product";
import { getProductLink } from "@fitness-app/utils/src/products/getProductLink";

import ModalForm from "~/components/ModalForm/ModalForm";
import { LIST_ITEM_SEPARATOR } from "~/constants/separators";
import { BillingPeriod } from "~/modules/Products/constants/productForm";
import PricesForm from "~/modules/Products/Product/PricesForm/PricesForm";
import { CreateCheckoutVariantLink } from "~/modules/Products/Product/ProductDetails/components/CreateCheckoutVariantLink";
import { ProductCheckoutVariants } from "~/modules/Products/Product/ProductDetails/components/ProductCheckoutVariants";
import ProductPage from "~/modules/Products/Product/ProductDetails/components/ProductPage";
import ProductPrices from "~/modules/Products/Product/ProductPrices/ProductPrices";
import ProductForm from "~/modules/Products/ProductForm/ProductForm";
import { type PricesFormModel, type ProductFormModel } from "~/modules/Products/ProductForm/types";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";

const ProductDetails: FunctionComponent = () => {
  const { t } = useTranslation(["products", "common"]);
  const { details, productDetailsStatus, productPricesActions } = useAppSelector((store) => store.product);
  const products = useAppSelector((store) => store.products.productsListData);
  const integration = useAppSelector((store) => store.productsSettings.data);

  const [productModel, setProductModel] = useState<ProductFormModel | null>(null);
  const [showProductForm, toggleProductForm] = useState<boolean>(false);
  const [showPricesForm, togglePricesForm] = useState<boolean>(false);
  const productAutomation = useAppSelector((store) => store.productAutomation.data);
  const { md } = Grid.useBreakpoint();
  const dispatch = useAppDispatch();

  const handleEditingProductModel = () => {
    if (!details) {
      return;
    }

    const model: ProductFormModel = {
      images: details.images || null,
      name: details.name,
      author: details.customAuthor || null,
      description: details.description || "",
      productChargeType: details.chargeType,
      isFree: details.chargeType === ProductChargeType.FREE,
      customInvoicesClient: details.customInvoicesClient
        ? `${details.customInvoicesClient.accountName}${LIST_ITEM_SEPARATOR}${details.customInvoicesClient.client}${LIST_ITEM_SEPARATOR}${details.customInvoicesClient.accountUrl}`
        : null,
      productType: details.type,
      upsellTo: Array.isArray(details.upsellTo) ? details.upsellTo.map((product) => product.productId) : [],
      endAccessOffers: Array.isArray(details.endAccessOffers)
        ? details.endAccessOffers.map((product) => product.productId)
        : [],
      productDurationType: details.productDuration?.type || null,
      productDuration:
        details.productDuration?.type === ProductDurationType.UntilDate
          ? {
              untilDate: dayjs(details.productDuration.date),
            }
          : details.productDuration?.type === ProductDurationType.LastsFor
          ? {
              unit: details.productDuration.unit,
              count: details.productDuration.count,
            }
          : undefined,
      prices:
        details.chargeType === ProductChargeType.FREE
          ? []
          : details.prices.map((price) => ({
              id: price.id,
              amount: price.unit_amount ? price.unit_amount / 100 : 0,
              currency: price.currency,
              paymentType: price.type,
              paymentClient: price.paymentClient,
              billingPeriod:
                price.type === PaymentTypeOption.Recurring && price.stripePrice.recurring
                  ? [
                      price.stripePrice.recurring?.interval_count || "1",
                      price.stripePrice.recurring?.interval || "month",
                    ].join("-")
                  : null,
            })),
    };

    if (details.productDuration?.type === ProductDurationType.UntilDate) {
      model.productDuration;
    }

    setProductModel(model);
    toggleProductForm(true);
  };

  const handleEditingPricesModel = () => {
    if (!details) {
      return;
    }
    const model: ProductFormModel = {
      images: details.images || null,
      name: details.name,
      description: details.description || "",
      productType: details.type,
      productChargeType: details.chargeType,
      productDurationType: null,
      author: details.customAuthor || null,
      prices:
        details.chargeType === ProductChargeType.FREE
          ? []
          : details.prices.map((price) => ({
              id: price.id,
              amount: price.unit_amount ? price.unit_amount / 100 : 0,
              currency: price.currency,
              paymentType: price.type,
              paymentClient: price.paymentClient,
              endAccessStrategy:
                price.accessPeriod?.type === PaymentTypeOption.OneTime
                  ? price.accessPeriod.endAccessStrategy
                  : undefined,
              billingPeriod:
                price.type === PaymentTypeOption.Recurring && price.stripePrice.recurring
                  ? [
                      price.stripePrice.recurring?.interval_count || "1",
                      price.stripePrice.recurring?.interval || "month",
                    ].join("-")
                  : null,
            })),
    };
    setProductModel(model);
    togglePricesForm(true);
  };

  const handleSubmit = async (model: ProductFormModel) => {
    if (!details?.id) {
      void message.warning("Problem with load form model");
      return;
    }

    const { customInvoicesClient } = model;

    const [invoiceClientAccountName, invoiceClient, invoiceClientUrl] =
      customInvoicesClient?.split(LIST_ITEM_SEPARATOR) ?? [];

    const formattedData: UpdateProductDTO = {
      id: details.id,
      status: details.status,
      upsellTo: model.upsellTo
        ?.map((id) => {
          const found = products.find((product) => product.id === id);
          if (found) {
            return {
              productName: found.name,
              productId: found.id,
              checkoutId: integration?.stripeConnectedAccountId || null,
            };
          } else {
            return null;
          }
        })
        .filter((item): item is UpsellTo => !!item),
      endAccessOffers: model.endAccessOffers
        ?.map((id) => {
          const found = products.find((product) => product.id === id);
          if (found) {
            return {
              productName: found.name,
              productId: found.id,
            };
          } else {
            return null;
          }
        })
        .filter((item): item is EndAccessOffers => !!item),
      customInvoicesClient:
        customInvoicesClient && productModel?.productChargeType !== ProductChargeType.FREE
          ? {
              accountUrl: invoiceClientUrl || "",
              accountName: invoiceClientAccountName || "",
              client: invoiceClient as InvoicesClient,
            }
          : null,
      description: model.description,
      name: model.name,
      images: model.images?.map((image) => (typeof image === "string" ? image : image.url)) ?? [],
    };
    const onSuccess = () => {
      toggleProductForm(false);
      setProductModel(null);
    };

    await dispatch(productActions.updateProduct({ payload: formattedData, onSuccess }));
  };

  const handlePricesSubmit = async (model: PricesFormModel) => {
    if (!details?.id) {
      void message.warning("Problem with load form model");
      return;
    }
    const formattedData: UpdateProductPriceDTO[] = model.prices.map((price) => {
      const formattedPrice =
        price.paymentType === PaymentTypeOption.Recurring && price.billingPeriod
          ? ({
              amount: price.amount,
              currency: price.currency,
              type: PaymentTypeOption.Recurring,
              active: true,
              interval_count:
                price.billingPeriod === BillingPeriod.Custom
                  ? price.billingIntervalCount || 1
                  : Number(price.billingPeriod.split("-")[0]),
              interval:
                price.billingPeriod === BillingPeriod.Custom
                  ? price.billingInterval || "month"
                  : (price.billingPeriod.split("-")[1] as RecurringInterval),

              paymentClient: price.paymentClient,
              ...(price.installments && { installments: price.installments }),
              ...(price.activeCustomDuration && {
                customDuration: price.customDuration
                  ? {
                      count: price.customDuration.count,
                      unit: price.customDuration.unit,
                    }
                  : null,
              }),
            } as RecurringPrice)
          : ({
              amount: price.amount,
              currency: price.currency,
              active: true,
              type: PaymentTypeOption.OneTime,
              paymentClient: price.paymentClient,
              endAccessStrategy: price.endAccessStrategy || EndAccessStrategy.Cancel,
              ...(price.billingPeriod && price.billingPeriod !== BillingPeriod.ToCancel
                ? {
                    interval_count:
                      price.billingPeriod === BillingPeriod.Custom
                        ? price.billingIntervalCount || 1
                        : Number(price.billingPeriod.split("-")[0]),
                    interval:
                      price.billingPeriod === BillingPeriod.Custom
                        ? price.billingInterval || "month"
                        : (price.billingPeriod.split("-")[1] as RecurringInterval),
                  }
                : {}),
            } as OneTimePrice);
      return {
        price: formattedPrice,
        productId: details.id,
        action: ProductPriceAction.Create,
      };
    });

    const onSuccess = () => {
      togglePricesForm(false);
      setProductModel(null);
    };

    await dispatch(
      productActions.updateProductPrices({
        onFailure: () => {
          void message.error(t("errorWhenUpdatingPrice"));
        },
        onSuccess,
        payload: {
          prices: formattedData,
        },
      }),
    );
  };

  return (
    <>
      <Card
        loading={productDetailsStatus === RequestStatus.SUBSCRIBING}
        extra={
          <Button type="primary" onClick={handleEditingProductModel}>
            {t("button.editProduct")}
          </Button>
        }
      >
        <Space direction="vertical" size={24}>
          {details && (
            <Descriptions bordered labelStyle={{ width: md ? 250 : 320 }} layout={md ? "horizontal" : "vertical"}>
              {details.images.length ? (
                <Descriptions.Item label={t("productDetails.photo")} span={3}>
                  <div
                    style={{
                      width: "auto",
                      maxWidth: "150px",
                      maxHeight: "250px",
                      padding: 10,
                    }}
                  >
                    <img alt="product" src={details.images[0]} style={{ maxWidth: "100%", height: "auto" }} />
                  </div>
                </Descriptions.Item>
              ) : null}
              <Descriptions.Item label={t("productDetails.id")} span={3}>
                <Typography.Paragraph
                  copyable={{
                    tooltips: [details.id, t("common:copied")],
                    text: details.id,
                  }}
                  style={{ marginBottom: 0 }}
                  strong
                >
                  {truncate(details.id, { length: 20 })}
                </Typography.Paragraph>
              </Descriptions.Item>
              {details && (
                <Descriptions.Item label={t("productDetails.productPage")} span={3}>
                  <ProductPage product={details} />
                </Descriptions.Item>
              )}
              <Descriptions.Item label={t("productDetails.name")} span={3}>
                {details.name}
              </Descriptions.Item>

              <Descriptions.Item label={t("productDetails.description")} span={3}>
                {details.description}
              </Descriptions.Item>

              <Descriptions.Item label={t("productDetails.type")} span={3}>
                {t(`productType.${details.type.toLowerCase()}`)}
              </Descriptions.Item>

              {details.productDuration && (
                <Descriptions.Item label={t("productDetails.productDurationType")} span={3}>
                  <Typography.Text strong>
                    {t(`productDurationType.${details.productDuration.type}`)}
                    {details.productDuration.type === ProductDurationType.LastsFor
                      ? ` - ${t(`recurring.${details.productDuration.unit}`, {
                          count: details.productDuration.count,
                        })}`
                      : details.productDuration.type === ProductDurationType.UntilDate
                      ? `- ${dayjs(details.productDuration.date).format("DD.MM.YYYY")}`
                      : ""}
                  </Typography.Text>
                </Descriptions.Item>
              )}

              <Descriptions.Item label={t("productDetails.paymentType")} span={3}>
                {t(details.chargeType !== ProductChargeType.FREE ? `productDetails.paid` : "productDetails.isFree")}
              </Descriptions.Item>

              {details.chargeType !== ProductChargeType.FREE && (
                <Descriptions.Item label={t("productDetails.invoiceClient")} span={3}>
                  {details.customInvoicesClient
                    ? `${details.customInvoicesClient.accountName} - ${t(
                        `common:invoices.${details.customInvoicesClient.client}`,
                      )}`
                    : "-"}
                </Descriptions.Item>
              )}

              <Descriptions.Item label={t("productDetails.active")} span={3}>
                <Switch
                  loading={productDetailsStatus === RequestStatus.UPDATING}
                  checked={details.status === ProductStatus.ACTIVE}
                  onChange={() =>
                    dispatch(
                      productActions.updateProduct({
                        payload: {
                          id: details.id,
                          status:
                            details.status === ProductStatus.ACTIVE ? ProductStatus.INACTIVE : ProductStatus.ACTIVE,
                          description: details.description ?? "",
                          images: details.images,
                          name: details.name,
                        },
                      }),
                    )
                  }
                />
              </Descriptions.Item>

              <Descriptions.Item label={t("productDetails.linkToFree")} span={3}>
                <Tooltip title={t("list.copyToClipboard")}>
                  <LinkOutlined
                    style={{ fontSize: 22 }}
                    onClick={() =>
                      navigator.clipboard.writeText(
                        getProductLink({
                          productId: details.id,
                          domain: import.meta.env.VITE_ECOMMERCE_DOMAIN,
                          customDomain: productAutomation?.settings?.embeddedForm?.customDomain,
                          ...(details.chargeType === ProductChargeType.FREE ? { priceId: "free" } : {}),
                        }),
                      )
                    }
                  />
                </Tooltip>
              </Descriptions.Item>

              <Descriptions.Item label={t("productDetails.totalClients")} span={3}>
                {details.product_stats?.totalClients || 0}
              </Descriptions.Item>
              {details.chargeType !== ProductChargeType.FREE && (
                <>
                  <Descriptions.Item label={t("productDetails.activeClients")} span={3}>
                    <Tooltip title={t("productDetails.activeClientsDesc")}>
                      {details.product_stats?.activeClients ?? "-"}
                    </Tooltip>
                  </Descriptions.Item>
                  <Descriptions.Item label={t("productDetails.churnedClients")} span={3}>
                    <Tooltip title={t("productDetails.churnedClientsDesc")}>
                      {details.product_stats?.archivedClients ?? "-"}
                    </Tooltip>
                  </Descriptions.Item>
                </>
              )}
            </Descriptions>
          )}
        </Space>
        {details?.status !== ProductStatus.ACTIVE && details?.prices.length ? (
          <div className="py-8">
            <Alert showIcon message={t("productDetails.inactivePrices")} type="warning" />
          </div>
        ) : null}
        {details?.prices.length && details?.status === ProductStatus.ACTIVE ? (
          <Tabs defaultActiveKey="prices" className="mt-8">
            <Tabs.TabPane key="prices" tab="Ceny">
              <div className="flex justify-end gap-3 py-4">
                <Button type="primary" onClick={handleEditingPricesModel}>
                  {t("button.addNewPrices")}
                </Button>
                <CreateCheckoutVariantLink productSlug={details.slug} productId={details.id} prices={details.prices} />
              </div>
              <ProductPrices
                extended
                productSlug={details.slug ?? ""}
                prices={details.prices}
                productId={details.id}
                productName={details.name}
              />
              <div className="mt-8">
                <Typography.Text type="secondary">{t("prices.requirements")}</Typography.Text>
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane key="variants" tab="Warianty koszyka">
              <ProductCheckoutVariants prices={details.prices} productId={details.id} />
            </Tabs.TabPane>
          </Tabs>
        ) : null}
      </Card>
      <ModalForm<ProductFormModel>
        okText={t("common:button.update")}
        open={showProductForm}
        onCancel={() => {
          toggleProductForm(false);
          setProductModel(null);
        }}
        title={t<string>("products:form.editTitle")}
        loading={productDetailsStatus === RequestStatus.UPDATING}
      >
        <ProductForm onSubmit={handleSubmit} model={productModel} />
      </ModalForm>
      <ModalForm<ProductFormModel>
        open={showPricesForm}
        width={900}
        onCancel={() => {
          togglePricesForm(false);
          setProductModel(null);
        }}
        title={t<string>("products:formPrices.title")}
        loading={!!productPricesActions?.length}
      >
        <PricesForm
          onSubmit={handlePricesSubmit}
          model={null}
          productType={details?.type || ProductType.PERSONAL_CLIENT_SERVICE}
        />
      </ModalForm>
    </>
  );
};

export default ProductDetails;
