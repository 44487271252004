import { createAsyncThunk } from "@reduxjs/toolkit";

import { UserRole } from "@fitness-app/data-models";
import {
  type DietaryPreference,
  type MacroSplit,
  type MealsPlanWithCreator,
} from "@fitness-app/data-models/entities/MealsPlan";

import { getLoggedUser } from "../../../helpers/getLoggedUser";
import { type AsyncThunkCreator } from "../../../index";
import { getUserRole } from "../../user/selectors";
import { MEALS_PLANS_REDUCER_NAME } from "../types";

type Payload = {
  page?: number;
  filter?: "only_author";
  tags?: string[] | null;
  searchTerm?: string | null;
  assignedTrainerId?: string | null;
  caloriesFilter?: {
    type: "greater" | "less";
    value: number;
  } | null;
  dietaryPreference?: DietaryPreference;
  macroSplit?: MacroSplit;
};

export const fetchMealsPlan = createAsyncThunk<
  { data: MealsPlanWithCreator[]; page: number; totalPages: number },
  Payload | void,
  AsyncThunkCreator<string>
>(`${MEALS_PLANS_REDUCER_NAME}/fetchMealsPlan`, async (payload, { rejectWithValue, getState, extra: { db, auth } }) => {
  const { listPerPage, searchTerm, filters } = getState().mealsPlans;
  const currentUser = await getLoggedUser(auth);
  const role = getUserRole(getState());
  const currentPage = payload?.page ?? 1;

  const start = (currentPage - 1) * listPerPage;
  const stop = currentPage * listPerPage - 1;

  let query = db.from("meals_plan").select("*, creator:createdBy(id, firstName, lastName)", { count: "exact" });

  if (role !== UserRole.TRAINER && !payload?.filter) {
    query = query.or(`createdBy.eq.${currentUser.id}, shared.is.${true}`);
  }

  if (payload?.assignedTrainerId) {
    if (payload.assignedTrainerId === "none") {
      query = query.is("createdBy", null);
    } else {
      query = query.eq("createdBy", payload.assignedTrainerId);
    }
  } else if (filters.currentAssignedTrainerFilter) {
    query = query.eq("createdBy", filters.currentAssignedTrainerFilter);
  }

  if (payload && payload.filter === "only_author") {
    query = query.eq("createdBy", currentUser.id);
  }

  if (payload?.tags?.length) {
    query = query.or(payload.tags.map((tag) => `tags.cs.["${tag}"]`).join(","));
  }

  if (!payload?.tags && filters.currentFilteredTags?.length) {
    query = query.or(filters.currentFilteredTags.map((tag) => `tags.cs.["${tag}"]`).join(","));
  }

  if (payload?.caloriesFilter) {
    const { type, value } = payload.caloriesFilter;
    if (type === "greater") {
      query = query.gte("targetCalories", value);
    } else if (type === "less") {
      query = query.lte("targetCalories", value);
    }
    query = query.order("targetCalories");
  } else if (filters.caloriesFilter) {
    const { type, value } = filters.caloriesFilter;
    if (type === "greater") {
      query = query.gte("targetCalories", value);
    } else if (type === "less") {
      query = query.lte("targetCalories", value);
    }
    query = query.order("targetCalories");
  }

  if (payload?.dietaryPreference) {
    query = query.eq("dietaryPreference", payload.dietaryPreference);
  } else if (filters.dietaryPreference) {
    query = query.eq("dietaryPreference", filters.dietaryPreference);
  }

  if (payload?.macroSplit) {
    query = query.eq("macroSplit", payload.macroSplit);
  } else if (filters.macroSplit) {
    query = query.eq("macroSplit", filters.macroSplit);
  }

  if (searchTerm) {
    query = query.or(`name.ilike.%${searchTerm}%`);
  }

  const { error, data, count } = await query
    .order("createdAt", { ascending: false })
    .range(start, stop)
    .returns<MealsPlanWithCreator[]>();

  if (error) {
    return rejectWithValue(error.message);
  }

  return { data, page: currentPage, totalPages: count ? Math.ceil(count / listPerPage) : 0 };
});
