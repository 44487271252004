import React, { useState } from "react";
import { ZoomInOutlined } from "@ant-design/icons";
import { useQueryClient } from "@tanstack/react-query";
import { Button, List, Modal, Popconfirm } from "antd";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { traineeMealsPlanActions } from "@fitness-app/app-store";
import { TargetGender, type DietaryExclusions } from "@fitness-app/data-models/entities/MealsPlan";
import { type TraineeNutritionGeneratorFormModel } from "@fitness-app/utils/src/nutrition/mealsPlanGenerators";

import { useUserRole } from "~/hooks/trainer/useUserRole";
import { useEntityChange } from "~/hooks/useEntityChange";
import { useMealPlanOptions } from "~/modules/Nutrition/hooks/useMealPlanOptions";
import { useDraftNutritionPlans } from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeNutrition/hooks/useDraftNutritionPlans";
import { useAppDispatch } from "~/store/initializeStore";

interface TraineeNutritionDraftsListProps {
  traineeId: string;
  generateNewDraft: (model: Partial<TraineeNutritionGeneratorFormModel> | undefined) => void;
}

export const TraineeNutritionDraftsList = ({
  traineeId,
  generateNewDraft,
}: TraineeNutritionDraftsListProps): React.ReactElement | null => {
  const { drafts, isLoading } = useDraftNutritionPlans(traineeId);
  const [showDraftsList, toggleDraftsList] = useState(false);
  const { isTeamMember, capabilities } = useUserRole();
  const [_searchParams, setSearchParams] = useSearchParams();
  const { modelsByKeys } = useMealPlanOptions();
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const [deleting, onSuccessDelete, onFailureDelete, onStartDelete] = useEntityChange(() => {
    void queryClient.invalidateQueries(["draftNutritionPlans"]);
  });
  const { t } = useTranslation(["nutrition", "common"]);

  if (!drafts || drafts.length === 0 || (isTeamMember && !capabilities.nutritionGenerator)) {
    return null;
  }

  const deleteNutrition = async (id: string) => {
    try {
      onStartDelete();
      await dispatch(
        traineeMealsPlanActions.deleteScheduledNutrition({
          mealsPlanId: id,
          traineeId,
        }),
      ).unwrap();
      onSuccessDelete();
    } catch {
      onFailureDelete();
    }
  };

  return (
    <div>
      <Button size="small" icon={<ZoomInOutlined />} onClick={() => toggleDraftsList(true)}>
        Pokaż listę draftów
      </Button>
      <Modal
        width={950}
        title="Lista zapisanych draftów"
        open={showDraftsList}
        cancelText="Zamknij"
        onCancel={() => toggleDraftsList(false)}
        okButtonProps={{ style: { display: "none" } }}
      >
        <List
          className="demo-loadmore-list"
          loading={isLoading}
          itemLayout="horizontal"
          dataSource={drafts}
          renderItem={(item) => (
            <List.Item
              actions={[
                <Button
                  size="small"
                  key="show"
                  type="link"
                  onClick={() => {
                    toggleDraftsList(false);
                    setSearchParams({ template: "true", planId: item.id, tab: "nutrition", nutritionTab: "schedule" });
                  }}
                >
                  Wyświetl
                </Button>,
                <Button
                  size="small"
                  key="show"
                  type="link"
                  onClick={() => {
                    generateNewDraft({
                      name: item.name,
                      targetCalories: item.targetCalories,
                      macroSplit: item.macroSplit,
                      dietaryPreference: item.dietaryPreference,
                      mealsSchema: item.mealsSchema,
                      targets: {
                        fat: item.targets.fat,
                        carbs: item.targets.carbs,
                        protein: item.targets.protein,
                        gender: item.targets.gender || TargetGender.UNISEX,
                        activityLevel: item.targets.activityLevel,
                        macroUnit: item.targets.macroUnit,
                      },
                      exclusions: Array.isArray(item.metadata?.exclusions)
                        ? (item.metadata?.exclusions as DietaryExclusions[])
                        : [],
                      prompt: String(item.metadata?.prompt || ""),
                      model: item.metadata?.model as
                        | "gemini-2.0-flash-001"
                        | "claude-3-5-sonnet-latest"
                        | "gpt-o3-mini"
                        | "gemini-2.0-pro-exp-02-05"
                        | "gemini-2.5-pro",
                    });
                    toggleDraftsList(false);
                  }}
                >
                  Generuj podobny
                </Button>,
                <Popconfirm
                  key="delete"
                  title="Czy na pewno chcesz usunąć tę dietę?"
                  arrow={false}
                  okButtonProps={{ danger: true }}
                  description="Ta operacja jest nieodwracalna."
                  onConfirm={() => deleteNutrition(item.id)}
                >
                  <Button type="link" danger size="small" key="delete" loading={Boolean(deleting)}>
                    Usuń
                  </Button>
                </Popconfirm>,
              ]}
            >
              <List.Item.Meta
                title={`${item.name} (${modelsByKeys[String(item.metadata?.model)]?.shortLabel || "Claude 3 Haiku"})`}
                description={`${item.targetCalories} kcal, ${t(`dietaryPreferenceEnum.${item.dietaryPreference}`)}, ${t(
                  `macroSplitDetailedShort.${item.macroSplit}`,
                )}`}
              />
            </List.Item>
          )}
        />
      </Modal>
    </div>
  );
};
