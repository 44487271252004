import React, { useMemo, useState } from "react";
import { Button, Dropdown, message } from "antd";

import { mealsPlanActions, traineeMealsPlanActions } from "@fitness-app/app-store";
import { TargetGender } from "@fitness-app/data-models/entities/MealsPlan";
import { generateMealsPlanDetails } from "@fitness-app/utils/src/nutrition/generateMealsPlanDetails";
import {
  transformAddMealPlanModelToMealsPlan,
  type AddMealPlanModel,
  type NutrientsTargets,
} from "@fitness-app/utils/src/nutrition/mealsPlanGenerators";

import ModalForm from "~/components/ModalForm/ModalForm";
import { useUserRole } from "~/hooks/trainer/useUserRole";
import { useEntityChange } from "~/hooks/useEntityChange";
import AddMealPlan from "~/modules/Nutrition/AddMealPlan/AddMealPlan";
import { useMealsPlanContext } from "~/shared/providers/MealsPlanProvider";
import { useAppDispatch } from "~/store/initializeStore";

interface TraineeNutritionMoreProps {
  traineeId: string;
  assignedPlanId: string;
  generatePdfLoading: boolean;
  setGeneratePdfLoading: (value: boolean) => void;
  current?: boolean;
}

export const TraineeNutritionMore = ({
  traineeId,
  assignedPlanId,
  setGeneratePdfLoading,
  generatePdfLoading,
  current,
}: TraineeNutritionMoreProps): React.ReactElement => {
  const dispatch = useAppDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { plan, planDetails } = useMealsPlanContext();
  const { userId, isTrainer } = useUserRole();
  const [loading, onSuccess, onFailure, onStart] = useEntityChange(() => setIsModalOpen(false));

  const generatePdf = async (): Promise<void> => {
    setGeneratePdfLoading(true);
    void message.loading({
      content: "Generowanie może potrwać nawet kilkanaście sekund...",
      key: "generatePdf",
      duration: 0,
    });

    const response = await dispatch(
      mealsPlanActions.generatePdfForTemplate({
        traineeId,
        traineeNutritionId: assignedPlanId,
        download: false,
        breakable: true,
      }),
    );
    message.destroy("generatePdf");
    if (response.type.includes("fulfilled")) {
      window.open(response.payload);
      await dispatch(traineeMealsPlanActions.fetchMealsPlanWithDetails({ id: assignedPlanId, refetch: true }));
    } else {
      // @ts-expect-error ignore
      void message.error(`Błąd podczas generowania PDF: ${response.error?.message}`);
    }
    setGeneratePdfLoading(false);
  };

  const dropdownOptions = useMemo(() => {
    return [
      {
        key: "1",
        label: "Zapisz jako szablon",
        onClick: () => setIsModalOpen(true),
      },
    ];
  }, []);

  const saveAsTemplate = async (data: AddMealPlanModel, nutrients: NutrientsTargets) => {
    onStart();
    try {
      const modelToSave = transformAddMealPlanModelToMealsPlan(data, nutrients, userId, isTrainer);

      await dispatch(
        mealsPlanActions.addMealsPlan({
          mealsPlan: modelToSave,
          mealsPlanDetails: generateMealsPlanDetails(modelToSave, planDetails),
        }),
      ).unwrap();
      onSuccess();
    } catch {
      onFailure();
    }
  };

  return (
    <>
      {current ? (
        <Dropdown.Button
          menu={{ items: dropdownOptions }}
          size="small"
          className="[&>button]:w-full"
          onClick={() => generatePdf()}
          disabled={generatePdfLoading}
          loading={generatePdfLoading}
        >
          Zapisz do PDF
        </Dropdown.Button>
      ) : (
        <Button onClick={() => setIsModalOpen(true)} size="small">
          Zapisz jako szablon
        </Button>
      )}
      <ModalForm
        width={850}
        title="Zapisz dietę jako szablon"
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        loading={!!loading}
      >
        <AddMealPlan
          onSubmit={saveAsTemplate}
          blockMealsSchema
          model={{
            name: plan.name,
            tags: plan.tags || [],
            macroSplit: plan.macroSplit,
            dietaryPreference: plan.dietaryPreference,
            comment: plan.comment,
            mealsSchema: plan.mealsSchema,
            targets: {
              ...plan.targets,
              gender: plan.targets.gender || TargetGender.UNISEX,
            },
            targetCalories: plan.targetCalories,
          }}
        />
      </ModalForm>
    </>
  );
};
