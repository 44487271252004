import { lazy, Suspense, useEffect, useState } from "react";
import { DeleteOutlined, EditOutlined, ExperimentOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { useQueryClient } from "@tanstack/react-query";
import { Button, Card, Popconfirm, Skeleton, Spin, Tabs } from "antd";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { supplementsTemplatesActions, traineeMealsPlanActions } from "@fitness-app/app-store";
import { getParentTrainerId } from "@fitness-app/app-store/src/store/reducers/user/selectors";
import { ClientNutritionStatus } from "@fitness-app/data-models/entities/ClientNutrition";
import { TraineeStatus } from "@fitness-app/data-models/entities/Trainee";
import { type TraineeNutritionGeneratorFormModel } from "@fitness-app/utils/src/nutrition/mealsPlanGenerators";

import ModalForm from "~/components/ModalForm/ModalForm";
import { useUserRole } from "~/hooks/trainer/useUserRole";
import { useEntityChange } from "~/hooks/useEntityChange";
import { useChatMessageForm } from "~/modules/Chat/components/ChatNotificationContext/ChatMessageFormContext";
import MealsPlanAttachments from "~/modules/Nutrition/MealsPlans/MealsPlanDetails/tabs/MealsPlanAttachments/MealsPlanAttachments";
import { RevertLastChanges } from "~/modules/Nutrition/MealsPlans/MealsPlanDetails/tabs/MealsPlanSchedule/components/RevertLastChanges";
import MealsPlanSchedule from "~/modules/Nutrition/MealsPlans/MealsPlanDetails/tabs/MealsPlanSchedule/MealsPlanSchedule";
import MealsPlanShoppingList from "~/modules/Nutrition/MealsPlans/MealsPlanDetails/tabs/MealsPlanShoppingList/MealsPlanShoppingList";
import MealsPlanSupplements from "~/modules/Nutrition/MealsPlans/MealsPlanDetails/tabs/MealsPlanSupplements/MealsPlanSupplements";
import MealsPlanTargets from "~/modules/Nutrition/MealsPlans/MealsPlanDetails/tabs/MealsPlanTargets/MealsPlanTargets";
import { useAddNutritionToTrainee } from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeNutrition/hooks/useAddNutritionToTrainee";
import { useGenerateNutrition } from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeNutrition/hooks/useGenerateNutrition";
import { TraineeDishesOccurrence } from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeNutrition/TraineeDishesOccurrance";
import { TraineeNutritionDraftsList } from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeNutrition/TraineeNutritionDraftsList";
import TraineeNutritionForm from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeNutrition/TraineeNutritionForm";
import TraineeNutritionGeneratorForm from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeNutrition/TraineeNutritionGeneratorForm";
import { TraineeNutritionMore } from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeNutrition/TraineeNutritionMore";
import { TraineeNutritionNotes } from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeNutrition/TraineeNutritionNotes";
import TraineeMealsPlanProvider from "~/shared/providers/TraineeMealsPlanProvider";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";

interface TraineeNutritionTabsProps {
  assignedPlanId: string;
  traineeId: string;
  type?: ClientNutritionStatus;
  openCurrent?: (id?: string) => void;
  onGenerateNewDraft: (model: Partial<TraineeNutritionGeneratorFormModel> | undefined) => void;
}

const TABS_KEY = ["schedule", "targets", "shoppingList", "supplements", "attachments", "generator", "notes"];

const TraineeNutritionTabs = ({
  assignedPlanId,
  traineeId,
  type,
  openCurrent,
  onGenerateNewDraft,
}: TraineeNutritionTabsProps) => {
  const { t } = useTranslation(["nutrition", "common"]);
  const [searchParams, setSearchParams] = useSearchParams();
  const queryClient = useQueryClient();
  const tabFromUrl = searchParams.get("nutritionTab") || "";
  const tab = "nutrition";
  const activeTab = TABS_KEY.includes(tabFromUrl) ? tabFromUrl : TABS_KEY[0];
  const dispatch = useAppDispatch();
  const [showNutritionForm, toggleNutritionForm] = useState<boolean | "scheduled">(false);
  const [showNutritionGenerator, toggleNutritionGenerator] = useState<boolean | "scheduled">(false);
  const traineeProfile = useAppSelector((store) => store.trainee.profile);

  const { isTrainer, capabilities } = useUserRole();
  const parentId = useAppSelector(getParentTrainerId);
  const [generatePdfLoading, setGeneratePdfLoading] = useState(false);
  const traineeStatus = useAppSelector((state) => state.trainee.profile?.status);
  const numberOfAttachments = useAppSelector(
    (state) => state.traineeMealsPlan.selectedMealsPlan?.attachments || [],
  ).length;
  const metadata = useAppSelector((state) => state.traineeMealsPlan.selectedMealsPlan?.metadata);
  const updatedAt = useAppSelector((state) => state.traineeMealsPlan.selectedMealsPlanDetails?.[0]?.updatedAt);
  const { loading, addNutritionToTrainee } = useAddNutritionToTrainee(showNutritionForm, traineeProfile, () => {
    toggleNutritionForm(false);
  });
  const { generating, generateNutrition } = useGenerateNutrition(() => {
    toggleNutritionGenerator(false);
  });
  const [deleting, onSuccessDelete, onFailureDelete, onStartDelete] = useEntityChange(() => {
    void queryClient.invalidateQueries({ queryKey: ["scheduledNutrition", traineeId] });
    openCurrent?.();
  });
  const { open } = useChatMessageForm();

  useEffect(() => {
    void dispatch(supplementsTemplatesActions.fetchSupplementsTemplates());
  }, [dispatch]);

  const TraineePdfGenerator = lazy(() => import(`./TraineePdfGenerator/TraineePdfGenerator`));

  const deleteNutrition = async () => {
    try {
      onStartDelete();
      await dispatch(
        traineeMealsPlanActions.deleteScheduledNutrition({
          mealsPlanId: assignedPlanId,
          traineeId,
          taskId: metadata?.taskId as string,
        }),
      ).unwrap();
      onSuccessDelete();
    } catch {
      onFailureDelete();
    }
  };

  const archiveNutrition = async () => {
    try {
      onStartDelete();
      await dispatch(
        traineeMealsPlanActions.archiveCurrentNutrition({
          mealsPlanId: assignedPlanId,
          traineeId,
        }),
      ).unwrap();
      onSuccessDelete();
    } catch {
      onFailureDelete();
    }
  };

  const current = type === ClientNutritionStatus.InProgress;
  const inFuture = type === ClientNutritionStatus.Scheduled;

  return (
    <TraineeMealsPlanProvider id={assignedPlanId} traineeId={traineeId}>
      <>
        <Card>
          <div className="p-6">
            <Tabs
              tabBarExtraContent={
                <div className="flex flex-col gap-y-2 p-2">
                  <RevertLastChanges />
                  <TraineeNutritionNotes traineeId={traineeId} />

                  {type === ClientNutritionStatus.InProgress && (
                    <Button
                      size="small"
                      type="primary"
                      icon={<EditOutlined />}
                      onClick={() => toggleNutritionForm(true)}
                    >
                      Zmień plan
                    </Button>
                  )}

                  {type === ClientNutritionStatus.InProgress && (isTrainer || capabilities.nutritionGenerator) ? (
                    <Button
                      size="small"
                      type="primary"
                      icon={<ExperimentOutlined />}
                      onClick={() => toggleNutritionGenerator(true)}
                    >
                      Generuj plan
                    </Button>
                  ) : null}

                  <TraineeNutritionMore
                    traineeId={traineeId}
                    current
                    assignedPlanId={assignedPlanId}
                    generatePdfLoading={generatePdfLoading}
                    setGeneratePdfLoading={setGeneratePdfLoading}
                  />

                  {type === ClientNutritionStatus.InProgress && (
                    <TraineeNutritionDraftsList generateNewDraft={onGenerateNewDraft} traineeId={traineeId} />
                  )}

                  {type === ClientNutritionStatus.InProgress && (
                    <Popconfirm
                      title="Czy na pewno chcesz zarchiwizować tę dietę?"
                      description="Klient natychmiastowo straci dostęp do planu dietetycznego"
                      onConfirm={archiveNutrition}
                      okText="Tak"
                      cancelText="Nie"
                      icon={<QuestionCircleOutlined style={{ color: "red" }} />}
                      okButtonProps={{ danger: true }}
                      overlayStyle={{ maxWidth: 350 }}
                    >
                      <Button size="small" danger loading={Boolean(deleting)} icon={<DeleteOutlined />}>
                        Archiwizuj
                      </Button>
                    </Popconfirm>
                  )}

                  {type === ClientNutritionStatus.Scheduled && (
                    <Popconfirm
                      title="Czy na pewno usunąć dietę?"
                      description="Odzyskanie danych będzie niemożliwe."
                      onConfirm={deleteNutrition}
                      okText="Tak"
                      cancelText="Nie"
                      icon={<QuestionCircleOutlined style={{ color: "red" }} />}
                      okButtonProps={{ danger: true }}
                      overlayStyle={{ maxWidth: 350 }}
                    >
                      <Button size="small" danger loading={Boolean(deleting)} icon={<DeleteOutlined />}>
                        Usuń
                      </Button>
                    </Popconfirm>
                  )}
                </div>
              }
              tabPosition="left"
              defaultActiveKey="schedule"
              className="tabs-reset"
              activeKey={activeTab}
              onChange={(nutritionTab) => setSearchParams({ nutritionTab, tab, planId: assignedPlanId })}
            >
              <Tabs.TabPane key="schedule" tab={t("mealsPlanDetails.schedule")}>
                <MealsPlanSchedule />
                {(current || inFuture) && <TraineeDishesOccurrence traineeId={traineeId} planId={assignedPlanId} />}
                {current && updatedAt && traineeStatus === TraineeStatus.ACTIVE ? (
                  <div className="mt-3 flex items-center justify-end gap-2">
                    <span>
                      Ostatnia aktualizacja: <b>{dayjs(updatedAt).format("HH:mm DD.MM.YYYY")}</b>
                    </span>
                    <Button size="small" style={{ marginLeft: 10 }} onClick={() => open()}>
                      wyślij powiadomienie o aktualizacji
                    </Button>
                  </div>
                ) : null}
              </Tabs.TabPane>

              <Tabs.TabPane key="targets" tab={t("mealsPlanDetails.targets")}>
                <MealsPlanTargets readonly={type === ClientNutritionStatus.Archived} />
              </Tabs.TabPane>

              <Tabs.TabPane key="shoppingList" tab={t("mealsPlanDetails.shoppingList")}>
                <MealsPlanShoppingList openByDefault={false} />
              </Tabs.TabPane>

              <Tabs.TabPane key="supplements" tab={t("mealsPlanDetails.supplements")}>
                <MealsPlanSupplements readonly={type === ClientNutritionStatus.Archived} />
              </Tabs.TabPane>

              <Tabs.TabPane
                key="attachments"
                destroyInactiveTabPane
                tab={`${t("mealsPlanDetails.attachments")}${
                  numberOfAttachments > 0 ? ` (${numberOfAttachments})` : ""
                }`}
              >
                {generatePdfLoading ? (
                  <Skeleton />
                ) : (
                  <MealsPlanAttachments
                    planId={assignedPlanId}
                    traineeId={traineeId}
                    current={current}
                    storageRef={`${parentId}/clients/${traineeId}/mealsPlan/${assignedPlanId}`}
                  />
                )}
              </Tabs.TabPane>

              <Tabs.TabPane key="generator" tab="Generator PDF">
                <Suspense fallback={<Spin />}>
                  <TraineePdfGenerator current traineeId={traineeId} setGeneratePdfLoading={setGeneratePdfLoading} />
                </Suspense>
              </Tabs.TabPane>
            </Tabs>
          </div>
        </Card>
        <ModalForm
          open={Boolean(showNutritionForm)}
          loading={!!loading}
          onCancel={() => toggleNutritionForm(false)}
          title={t("mealsPlan.addNewTitle")}
          width={850}
        >
          <TraineeNutritionForm
            traineeId={traineeId}
            onSubmit={addNutritionToTrainee}
            inFuture={showNutritionForm === "scheduled"}
          />
        </ModalForm>
        <ModalForm
          open={Boolean(showNutritionGenerator)}
          loading={!!generating}
          onCancel={() => toggleNutritionGenerator(false)}
          title="Generuj nowy jadłospis"
          width={850}
          okText="Generuj"
        >
          <TraineeNutritionGeneratorForm traineeId={traineeId} onSubmit={generateNutrition} />
        </ModalForm>
      </>
    </TraineeMealsPlanProvider>
  );
};

export default TraineeNutritionTabs;
