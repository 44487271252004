import { useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { message as toast } from "antd";
import dayjs from "dayjs";
import slugify from "slugify";

import {
  CheckoutVariantStatus,
  type CheckoutVariant,
  type CheckoutVariantTarget,
} from "@fitness-app/data-models/entities/CheckoutVariant";
import { createRandomCode } from "@fitness-app/utils/src/helpers/createRandomCode";

import { useUserRole } from "~/hooks/trainer/useUserRole";
import { type ProductCheckoutVariantFormModel } from "~/modules/Products/Product/ProductDetails/components/types";
import { supabase } from "~/store/initializeStore";

export const useCheckoutVariants = (productId: string) => {
  const queryClient = useQueryClient();
  const [showFormModal, setShowFormModal] = useState(false);
  const [editedModel, setEditedModel] = useState<CheckoutVariant | null>(null);
  const { userId } = useUserRole();
  const [showSavingIndicator, setShowSavingIndicator] = useState(false);

  const { data, isLoading, refetch, isError } = useQuery(["checkoutVariants", productId], {
    queryFn: async () => {
      const { data, error } = await supabase
        .from("checkout_variant")
        .select("*")
        .in("status", [CheckoutVariantStatus.Active, CheckoutVariantStatus.Inactive])
        .eq("productId", productId)
        .order("createdAt", { ascending: false })
        .limit(100)
        .returns<CheckoutVariant[]>();

      if (error) {
        throw new Error(error.message);
      }

      if (!data) {
        return [];
      }

      return data;
    },
  });

  const updateVariant = async (
    variantId: string,
    variant: Partial<CheckoutVariant> & { target: CheckoutVariantTarget },
    optimistic = true,
  ) => {
    if (optimistic) {
      queryClient.setQueryData(["checkoutVariants", productId], (oldList: CheckoutVariant[] | undefined) => {
        return oldList?.map((item) =>
          item.id === variantId ? { ...item, ...variant, updatedAt: new Date().toISOString() } : item,
        );
      });
    }

    if (variant.default) {
      await supabase
        .from("checkout_variant")
        .update({ default: false })
        .eq("productId", productId)
        .eq("target", variant.target);
    }

    const { error } = await supabase
      .from("checkout_variant")
      .update({ ...variant, updatedAt: new Date().toISOString() })
      .eq("id", variantId)
      .single();

    if (error) {
      if (error.message.includes("duplicate key value violates unique constraint")) {
        void toast.error("Wariant o podanym slugu już istnieje. Wybierz inny slug.");
      } else {
        toast.error(error.message);
      }
      return "error";
    } else {
      void queryClient.refetchQueries(["checkoutVariants", productId]);
      return "success";
    }
  };

  const onVariantSubmit = async (model: ProductCheckoutVariantFormModel) => {
    setShowSavingIndicator(true);
    if (editedModel) {
      const response = await updateVariant(editedModel.id, {
        slug: slugify(model.slug, { lower: true }),
        status: model.isActive ? CheckoutVariantStatus.Active : CheckoutVariantStatus.Inactive,
        target: model.target,
        prices: model.prices,
        activeUntil: model.activeUntil ? dayjs(model.activeUntil).unix() : null,
      });
      setShowSavingIndicator(false);
      if (response === "success") {
        setShowFormModal(false);
        setEditedModel(null);
      }
    } else {
      const newVariant: Omit<CheckoutVariant, "id" | "createdAt" | "updatedAt" | "revenue" | "views" | "purchase"> = {
        productId,
        prices: model.prices,
        status: model.isActive ? CheckoutVariantStatus.Active : CheckoutVariantStatus.Inactive,
        slug: slugify(model.slug, { lower: true }),
        target: model.target,
        pricesStats: {},
        excluded: false,
        default: model.default,
        activeUntil: model.activeUntil ? dayjs(model.activeUntil).unix() : null,
        metadata: {
          addedBy: userId,
        },
        pageConfiguration: null,
      };

      if (newVariant.default) {
        await supabase
          .from("checkout_variant")
          .update({ default: false })
          .eq("productId", productId)
          .eq("target", newVariant.target);
      }

      const { error } = await supabase.from("checkout_variant").insert(newVariant).single();
      setShowSavingIndicator(false);
      if (error) {
        if (error.message.includes("duplicate key value violates unique constraint")) {
          void toast.error("Wariant o podanym slugu już istnieje. Wybierz inny slug.");
        } else {
          toast.error(error.message);
        }
      } else {
        setEditedModel(null);
        setShowFormModal(false);
        void queryClient.refetchQueries(["checkoutVariants", productId]);
      }
    }
  };

  const setEditedItem = (item: CheckoutVariant) => {
    setEditedModel(item);
    setShowFormModal(true);
  };

  const duplicateVariant = async (item: CheckoutVariant) => {
    void toast.info({ key: "duplicate-variant", content: "Duplikowanie wariantu...", duration: 0 });
    const { error } = await supabase
      .from("checkout_variant")
      .insert({
        productId,
        prices: item.prices,
        status: CheckoutVariantStatus.Inactive,
        slug: `${item.slug}-${createRandomCode(1, 5)[0]}`,
        target: item.target,
        pricesStats: [],
        default: false,
        activeUntil: null,
        excluded: item.excluded,
        metadata: {
          ...item.metadata,
          addedBy: userId,
        },
        pageConfiguration: item.pageConfiguration || null,
      })
      .single();

    void toast.destroy("duplicate-variant");

    if (error) {
      toast.error(error.message);
    } else {
      void queryClient.refetchQueries(["checkoutVariants", productId]);
    }
  };

  return {
    data,
    isLoading,
    refetch,
    isError,
    updateVariant,
    setShowFormModal,
    showFormModal,
    onVariantSubmit,
    editedModel,
    setEditedItem,
    showSavingIndicator,
    duplicateVariant,
  };
};
