import React, { useEffect, useState } from "react";
import { DeleteOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { useQueryClient } from "@tanstack/react-query";
import { Button, Card, Popconfirm, Tabs } from "antd";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { mealsPlanActions, supplementsTemplatesActions, traineeMealsPlanActions } from "@fitness-app/app-store";
import { ClientNutritionStatus, type ClientMealsPlanDetails } from "@fitness-app/data-models/entities/ClientNutrition";
import { type MealsPlanDetails } from "@fitness-app/data-models/entities/MealsPlan";
import { generateTraineeMealsPlanDetails } from "@fitness-app/utils/src/nutrition/generateMealsPlanDetails";
import {
  transformAddMealPlanModelToTraineeNutrition,
  type NutrientsTargets,
  type TraineeNutritionFormModel,
} from "@fitness-app/utils/src/nutrition/mealsPlanGenerators";

import ModalForm from "~/components/ModalForm/ModalForm";
import { useUserRole } from "~/hooks/trainer/useUserRole";
import { useEntityChange } from "~/hooks/useEntityChange";
import { RevertLastChanges } from "~/modules/Nutrition/MealsPlans/MealsPlanDetails/tabs/MealsPlanSchedule/components/RevertLastChanges";
import MealsPlanSchedule from "~/modules/Nutrition/MealsPlans/MealsPlanDetails/tabs/MealsPlanSchedule/MealsPlanSchedule";
import MealsPlanShoppingList from "~/modules/Nutrition/MealsPlans/MealsPlanDetails/tabs/MealsPlanShoppingList/MealsPlanShoppingList";
import MealsPlanSupplements from "~/modules/Nutrition/MealsPlans/MealsPlanDetails/tabs/MealsPlanSupplements/MealsPlanSupplements";
import MealsPlanTargets from "~/modules/Nutrition/MealsPlans/MealsPlanDetails/tabs/MealsPlanTargets/MealsPlanTargets";
import { TraineeDishesOccurrence } from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeNutrition/TraineeDishesOccurrance";
import TraineeNutritionForm from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeNutrition/TraineeNutritionForm";
import { TraineeNutritionMore } from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeNutrition/TraineeNutritionMore";
import { TraineeNutritionNotes } from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeNutrition/TraineeNutritionNotes";
import TraineeMealsPlanProvider from "~/shared/providers/TraineeMealsPlanProvider";
import { supabase, useAppDispatch, useAppSelector } from "~/store/initializeStore";

interface TraineeNutritionCreatorProps {
  nutritionId: string;
  traineeId: string;
  openCurrent: (id?: string) => void;
}

const TABS_KEY = ["schedule", "targets", "shoppingList", "supplements", "attachments", "generator", "notes"];

export const TraineeNutritionCreator = ({
  nutritionId: assignedPlanId,
  traineeId,
  openCurrent,
}: TraineeNutritionCreatorProps): React.ReactElement => {
  const { t } = useTranslation(["nutrition", "common"]);
  const [searchParams, setSearchParams] = useSearchParams();
  const queryClient = useQueryClient();
  const tabFromUrl = searchParams.get("nutritionTab") || "";
  const tab = "nutrition";
  const activeTab = TABS_KEY.includes(tabFromUrl) ? tabFromUrl : TABS_KEY[0];
  const dispatch = useAppDispatch();
  const [showNutritionForm, toggleNutritionForm] = useState<boolean | "scheduled">(false);
  const { userId } = useUserRole();
  const [generatePdfLoading, setGeneratePdfLoading] = useState(false);
  const metadata = useAppSelector((state) => state.traineeMealsPlan.selectedMealsPlan?.metadata);
  const [loading, onSuccess, onFailure, onStart] = useEntityChange(() => toggleNutritionForm(false));
  const [deleting, onSuccessDelete, onFailureDelete, onStartDelete] = useEntityChange(() => {
    openCurrent?.();
    void queryClient.invalidateQueries(["draftNutritionPlans"]);
  });

  useEffect(() => {
    void dispatch(supplementsTemplatesActions.fetchSupplementsTemplates());
  }, [dispatch]);

  const addNutritionToTrainee = async (
    formData: TraineeNutritionFormModel,
    targets: NutrientsTargets,
    supplementsFromCurrentPlan?: ClientMealsPlanDetails["supplements"],
  ) => {
    onStart();
    try {
      let supplements: ClientMealsPlanDetails["supplements"] = [];

      if (formData.copySupplementsPlan && supplementsFromCurrentPlan?.length) {
        supplements = supplementsFromCurrentPlan;
      }
      const modelToSave = transformAddMealPlanModelToTraineeNutrition({
        model: formData,
        nutrients: targets,
        traineeId,
        userId,
        metadata: {
          assignedBy: "trainer",
        },
      });

      let details: MealsPlanDetails | null = null;

      if (formData.selectedTemplate) {
        const response = await dispatch(
          mealsPlanActions.fetchMealsPlanWithDetails({ id: formData.selectedTemplate }),
        ).unwrap();

        details = response.details[0] || null;
      }

      if (showNutritionForm === "scheduled") {
        const { data } = await supabase
          .from("client_meals_plan")
          .select("id, name")
          .match({ traineeId, status: ClientNutritionStatus.Scheduled, startAt: modelToSave.startAt });

        if (data?.length) {
          onFailure(`Istnieje już plan rozpoczynający się w dniu ${modelToSave.startAt}`);
          return;
        }

        await dispatch(
          traineeMealsPlanActions.addScheduledNutritionToTrainee({
            nutrition: modelToSave,
            mealsPlanDetails: generateTraineeMealsPlanDetails(modelToSave, traineeId, details, supplements),
            traineeId,
          }),
        ).unwrap();
        await queryClient.invalidateQueries({ queryKey: ["scheduledNutrition", traineeId] });
        await queryClient.invalidateQueries({ queryKey: ["archivedNutritionPlans", traineeId] });
        onSuccess();
      } else {
        const response = await dispatch(
          traineeMealsPlanActions.addNutritionToTrainee({
            nutrition: modelToSave,
            mealsPlanDetails: generateTraineeMealsPlanDetails(modelToSave, traineeId, details, supplements),
            shareWithClient: formData.hasActiveNutrition || false,
          }),
        ).unwrap();
        await queryClient.invalidateQueries({ queryKey: ["archivedNutritionPlans", traineeId] });
        openCurrent?.(response?.plan?.id);
        onSuccess();
      }
    } catch (e) {
      onFailure();
    }
  };

  const deleteNutrition = async () => {
    try {
      onStartDelete();
      await dispatch(
        traineeMealsPlanActions.deleteScheduledNutrition({
          mealsPlanId: assignedPlanId,
          traineeId,
          taskId: metadata?.taskId as string,
        }),
      ).unwrap();
      onSuccessDelete();
    } catch {
      onFailureDelete();
    }
  };

  return (
    <TraineeMealsPlanProvider id={assignedPlanId} traineeId={traineeId}>
      <>
        <Card>
          <div className="p-6">
            <Tabs
              tabBarExtraContent={
                <div className="flex flex-col gap-y-2 p-2">
                  <RevertLastChanges />

                  <Button size="small" onClick={() => openCurrent()}>
                    Zamknij draft
                  </Button>

                  <TraineeNutritionNotes traineeId={traineeId} />

                  <TraineeNutritionMore
                    traineeId={traineeId}
                    current={false}
                    assignedPlanId={assignedPlanId}
                    generatePdfLoading={generatePdfLoading}
                    setGeneratePdfLoading={setGeneratePdfLoading}
                  />

                  <Popconfirm
                    title="Czy na pewno usunąć dietę?"
                    description="Odzyskanie danych będzie niemożliwe."
                    onConfirm={deleteNutrition}
                    okText="Tak"
                    cancelText="Nie"
                    icon={<QuestionCircleOutlined style={{ color: "red" }} />}
                    okButtonProps={{ danger: true }}
                    overlayStyle={{ maxWidth: 350 }}
                  >
                    <Button size="small" danger loading={Boolean(deleting)} icon={<DeleteOutlined />}>
                      Usuń
                    </Button>
                  </Popconfirm>
                </div>
              }
              tabPosition="left"
              defaultActiveKey="schedule"
              className="tabs-reset"
              activeKey={activeTab}
              onChange={(nutritionTab) =>
                setSearchParams({ nutritionTab, tab, planId: assignedPlanId, template: "true" })
              }
            >
              <Tabs.TabPane key="schedule" tab={t("mealsPlanDetails.schedule")}>
                <MealsPlanSchedule />
                <TraineeDishesOccurrence traineeId={traineeId} planId={assignedPlanId} />
              </Tabs.TabPane>

              <Tabs.TabPane key="targets" tab={t("mealsPlanDetails.targets")}>
                <MealsPlanTargets />
              </Tabs.TabPane>

              <Tabs.TabPane key="shoppingList" tab={t("mealsPlanDetails.shoppingList")}>
                <MealsPlanShoppingList openByDefault={false} />
              </Tabs.TabPane>

              <Tabs.TabPane key="supplements" tab={t("mealsPlanDetails.supplements")}>
                <MealsPlanSupplements />
              </Tabs.TabPane>
            </Tabs>
          </div>
        </Card>
        <ModalForm
          open={Boolean(showNutritionForm)}
          loading={!!loading}
          onCancel={() => toggleNutritionForm(false)}
          title={t("mealsPlan.addNewTitle")}
          width={850}
        >
          <TraineeNutritionForm
            traineeId={traineeId}
            onSubmit={addNutritionToTrainee}
            inFuture={showNutritionForm === "scheduled"}
          />
        </ModalForm>
      </>
    </TraineeMealsPlanProvider>
  );
};
