import { createAsyncThunk } from "@reduxjs/toolkit";

import { type AsyncThunkCreator } from "../../../index";
import { PRODUCT_CLIENT_REDUCER } from "../types";

type Payload =
  | {
      productId: string;
      clientEmail: string;
      resume_at: number | null;
    }
  | {
      productId: string;
      clientEmail: string;
      newBillingAnchor: number | null;
    };

export const setExtendAccessPeriod = createAsyncThunk<void, Payload, AsyncThunkCreator<unknown>>(
  `${PRODUCT_CLIENT_REDUCER}/setExtendAccessPeriod`,
  async (payload, { rejectWithValue, extra: { productApi, analytics } }) => {
    try {
      await productApi.post("/extend-client-access-period", payload);
      if ("resume_at" in payload && payload.resume_at !== null) {
        analytics.track("extend_access_period", {
          productId: payload.productId,
          clientEmail: payload.clientEmail,
          extendAbout: payload.resume_at,
        });
      } else if ("newBillingAnchor" in payload && payload.newBillingAnchor !== null) {
        analytics.track("change_billing_anchor", {
          productId: payload.productId,
          clientEmail: payload.clientEmail,
          extendAbout: payload.newBillingAnchor,
        });
      }
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);
