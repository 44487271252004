import { useEffect } from "react";
import { Form, Input, Select, Switch } from "antd";
import { type FormInstance } from "antd/lib/form";
import { useTranslation } from "react-i18next";

import { type PageConfiguration } from "@fitness-app/data-models/entities/CheckoutVariant";

import { colorThemeOptions } from "../../ProductSettings/utils/theme-options";

interface ProductPageConfigurationFormProps {
  formController?: FormInstance<PageConfiguration>;
  onSubmit: (formData: PageConfiguration) => void;
  model?: Partial<PageConfiguration> | null;
}

const ProductPageConfigurationForm = ({ model, onSubmit }: ProductPageConfigurationFormProps) => {
  const { t } = useTranslation(["products", "common"]);
  const [formController] = Form.useForm<PageConfiguration>();
  const showTimer = Form.useWatch("timer", formController);
  const pricingDisabled = Form.useWatch("pricingDisabled", formController);
  const showPromoVideo = Form.useWatch("promoVideo", formController);

  useEffect(() => {
    if (model) {
      formController.setFieldsValue({
        productNameOverride: model.productNameOverride ?? "",
        pricingDisabled: model.pricingDisabled ?? false,
        timer: model.timer ?? false,
        promoVideo: model.promoVideo ?? undefined,
      });
    }
  }, [model]);

  return (
    <Form<PageConfiguration>
      disabled={pricingDisabled}
      name="page-configuration-form"
      className="max-w-[500px]"
      layout="vertical"
      form={formController}
      onFinish={onSubmit}
      initialValues={{
        productNameOverride: model?.productNameOverride ?? "",
        pricingDisabled: model?.pricingDisabled ?? false,
      }}
    >
      <Form.Item
        name="productNameOverride"
        label={t<string>("pageConfig.productNameOverride")}
        tooltip={t<string>("pageConfig.productNameOverrideTooltip")}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="pricingDisabled"
        label={t<string>("pageConfig.pricingDisabled")}
        tooltip={t<string>("pageConfig.pricingDisabledTooltip")}
        valuePropName="checked"
      >
        <Switch />
      </Form.Item>

      <Form.Item
        name={["timer", "title"]}
        label={t<string>("pageConfig.timerTitle")}
        tooltip={t<string>("pageConfig.timerTitleTooltip")}
      >
        <Input />
      </Form.Item>

      {showTimer && (
        <>
          <Form.Item name={["timer", "description"]} label={t<string>("pageConfig.timerDescription")}>
            <Input.TextArea rows={3} />
          </Form.Item>

          <Form.Item name={["timer", "startInfo"]} label={t<string>("pageConfig.timerStartInfo")}>
            <Input />
          </Form.Item>

          <Form.Item name={["timer", "titleColor"]} label={t<string>("pageConfig.timerTitleColor")}>
            <Select>
              {colorThemeOptions().map((item) => (
                <Select.Option key={item.value} value={item.value}>
                  {t<string>(`${item.value.toUpperCase()}`)}
                  {item.color && (
                    <div
                      style={{
                        position: "absolute",
                        right: "1.2rem",
                        top: ".35rem",
                        width: "1.2rem",
                        height: "1.2rem",
                        backgroundColor: item.color,
                        borderRadius: ".6rem",
                      }}
                    />
                  )}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </>
      )}

      <Form.Item name="promoVideo" label={t<string>("pageConfig.promoVideo")} valuePropName="checked">
        <Switch />
      </Form.Item>

      {showPromoVideo && (
        <>
          <Form.Item name={["promoVideo", "title"]} label={t<string>("pageConfig.promoVideoTitle")}>
            <Input />
          </Form.Item>

          <Form.Item
            name={["promoVideo", "videoId"]}
            label={t<string>("pageConfig.promoVideoId")}
            tooltip={t<string>("pageConfig.promoVideoIdTooltip")}
            rules={[
              {
                required: true,
                message: t<string>("common:validationErrors.fieldIsRequired"),
              },
            ]}
          >
            <Input />
          </Form.Item>
        </>
      )}
    </Form>
  );
};

export default ProductPageConfigurationForm;
