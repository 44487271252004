import React from "react";
import { ClockCircleOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Badge, Popover, Space, Tag, Typography } from "antd";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import {
  type ProductClient,
  type SubscriptionProductAccessPeriod,
} from "@fitness-app/data-models/entities/ProductClient";

import { SUBSCRIPTION_STATUS_MAPPER } from "~/modules/Products/constants/subscriptionStatusMapper";
import InfoAboutExtendedAccessPeriod from "~/modules/Products/Product/ProductClientsList/components/InfoAboutExtendedAccessPeriod/InfoAboutExtendedAccessPeriod";

interface ProductSubscriptionInfoProps {
  accessPeriod: SubscriptionProductAccessPeriod;
  status?: ProductClient["status"];
  hideCycleInfo?: boolean;
}

const ProductSubscriptionInfo = ({
  accessPeriod,
  status = "active",
  hideCycleInfo = false,
}: ProductSubscriptionInfoProps): React.ReactElement => {
  const { t } = useTranslation(["client", "common", "products"]);
  const cancelAtPeriodEnd = accessPeriod.cancelAtPeriodEnd && status !== "archived";
  const hasInstallments = Boolean(accessPeriod.installments);

  if (hasInstallments) {
    return (
      <Popover
        title={t("installments.title")}
        content={
          <Space direction="vertical">
            <Space>
              <Typography.Text type="secondary">{t("subscription.status")}</Typography.Text>
              <Badge
                status={cancelAtPeriodEnd ? "warning" : SUBSCRIPTION_STATUS_MAPPER[accessPeriod.status]}
                text={t(`common:subscriptionStatus.${accessPeriod.status}`)}
              />
            </Space>

            {!hideCycleInfo && (
              <>
                <Space>
                  <Typography.Text type="secondary">{t("subscription.startFrom")}</Typography.Text>
                  <Typography.Text>
                    {dayjs.unix(accessPeriod.currentPeriodStart).format("DD.MM.YY (HH:mm)")}
                  </Typography.Text>
                </Space>
                <Space>
                  <Typography.Text type="secondary">{t("subscription.endTo")}</Typography.Text>
                  <Typography.Text>
                    {dayjs
                      .unix(accessPeriod?.pauseCollection?.resumes_at || accessPeriod.currentPeriodEnd)
                      .format("DD.MM.YY (HH:mm)")}
                  </Typography.Text>
                </Space>
              </>
            )}

            {accessPeriod.startAt && (
              <Space>
                <Typography.Text type="secondary">Data rozpoczęcia:</Typography.Text>
                <Typography.Text>{dayjs.unix(accessPeriod.startAt).format("DD.MM.YY (HH:mm)")}</Typography.Text>
              </Space>
            )}

            {accessPeriod.installmentEndsAt || accessPeriod.pauseCollection ? (
              <Space>
                <Typography.Text type="secondary">{t("installments.endDate")}:</Typography.Text>
                <Typography.Text>
                  {dayjs
                    .unix(
                      accessPeriod.pauseCollection?.resumes_at ||
                        accessPeriod.endDate ||
                        accessPeriod.installmentEndsAt!,
                    )
                    .format("DD.MM.YY (HH:mm)")}
                </Typography.Text>
              </Space>
            ) : accessPeriod.endDate ? (
              <Space>
                <Typography.Text type="secondary">{t("installments.endDate")}:</Typography.Text>
                <Typography.Text>{dayjs.unix(accessPeriod.endDate).format("DD.MM.YY (HH:mm)")}</Typography.Text>
              </Space>
            ) : null}

            {accessPeriod.installments && (
              <Space>
                <Typography.Text type="secondary">{t("installments.cycle")}:</Typography.Text>
                <Typography.Text>
                  {accessPeriod.cycleNumber} / {accessPeriod.installments}
                </Typography.Text>
              </Space>
            )}

            {accessPeriod.customDuration && (
              <Space>
                <Typography.Text type="secondary">Cykl dostępu:</Typography.Text>
                <Typography.Text>
                  {t(`products:recurring.${accessPeriod.customDuration.unit}`, {
                    count: accessPeriod.customDuration.count,
                  })}
                </Typography.Text>
              </Space>
            )}

            <InfoAboutExtendedAccessPeriod accessPeriod={accessPeriod} />

            <Space>
              <Typography.Text type="secondary">{t("subscription.type")}</Typography.Text>
              <Typography.Text>
                {t(`products:recurring.${accessPeriod.recurring?.interval}`, {
                  count: accessPeriod.recurring?.interval_count,
                })}
              </Typography.Text>
            </Space>
          </Space>
        }
      >
        <InfoCircleOutlined style={{ fontSize: 18, marginTop: 2 }} />
      </Popover>
    );
  }

  return (
    <Popover
      title={t("subscription.title")}
      content={
        <Space direction="vertical">
          <Space>
            <Typography.Text type="secondary">{t("subscription.status")}</Typography.Text>
            <Badge
              status={cancelAtPeriodEnd ? "warning" : SUBSCRIPTION_STATUS_MAPPER[accessPeriod.status]}
              text={t(`common:subscriptionStatus.${accessPeriod.status}`)}
            />
            {cancelAtPeriodEnd ? (
              <Tag icon={<ClockCircleOutlined />}>
                {t("subscription.endAt")} {dayjs.unix(accessPeriod.currentPeriodEnd).format("DD.MM")}
              </Tag>
            ) : null}
          </Space>
          <Space>
            <Typography.Text type="secondary">{t("subscription.startFrom")}</Typography.Text>
            <Typography.Text>{dayjs.unix(accessPeriod.currentPeriodStart).format("DD.MM.YY (HH:mm)")}</Typography.Text>
          </Space>
          <Space>
            <Typography.Text type="secondary">{t("subscription.endTo")}</Typography.Text>
            <Typography.Text>
              {dayjs
                .unix(accessPeriod?.pauseCollection?.resumes_at || accessPeriod.currentPeriodEnd)
                .format("DD.MM.YY (HH:mm)")}
            </Typography.Text>
          </Space>

          <InfoAboutExtendedAccessPeriod accessPeriod={accessPeriod} />

          <Space>
            <Typography.Text type="secondary">{t("subscription.type")}</Typography.Text>
            <Typography.Text>
              {t(`products:recurring.${accessPeriod.recurring?.interval}`, {
                count: accessPeriod.recurring?.interval_count,
              })}
            </Typography.Text>
          </Space>
        </Space>
      }
    >
      <InfoCircleOutlined style={{ fontSize: 18, marginTop: 2 }} />
    </Popover>
  );
};

export default ProductSubscriptionInfo;
