export const tagSubscriptionColorMapper = {
  past_due: "#ffa800",
  incomplete: "#ffa800",
  paused: "#ffa800",
  incomplete_expired: "#f64e60",
  canceled: "#f64e60",
  unpaid: "#f64e60",
  expired: "#f64e60",
  active: "#87d068",
  paid: "#87d068",
  imported: "#87d068",
  trialing: "#2db7f5",
  cancelAtEnd: "#ffa800",
} as const;
